// Text Ready OK
import React from 'react';

import CloudServiceImage from '../../../../components/Images/infographics/services-cloud';

const SecondPage = () => (
    <>
        <h4 className="text-xl mb-4 font-light">
            Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity
        </h4>
        <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight">
            Securing mission-critical operations
        </h1>
        {/* <div className="text-2xl font-light pb-5 tracking-normal tracking-tight">
            <p>Technology support for hybrid cloud environments that are global, open and constantly changing</p>
        </div>
        <div className="lg:w-2/3 mx-auto">
            <CloudServiceImage />
        </div> */}
    </>
);

export default SecondPage;
