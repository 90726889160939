// Text Ready OK
import React from 'react';

import CloudServiceImage from '../../../components/Images/infographics/machine-learning';

const SecondPage = () => (
    <>
        <h4 className="text-xl mb-4 font-light">
            Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity
        </h4>
        <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight" textReady={80}>
            Technology as the foundation of a robust and forward leaning business.
        </h1>
        <div className="text-2xl font-light pb-5 tracking-normal tracking-tight" textReady={60}>
            <p>
                We help make your workflows more intelligent. Your audience interactions more meaningful. Your teams
                more efficient. Your operations more cost-effective. And your process more powerful.
            </p>
        </div>

        <p textReady={80}>Together we can enhance all layers of your business with digital modernization.</p>

        <div className="lg:w-2/3 mx-auto mt-8">
            <CloudServiceImage />
        </div>
    </>
);

export default SecondPage;
