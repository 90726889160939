// textReady OK
import React from 'react';

// import { graphql, useStaticQuery } from 'gatsby';
// import BackgroundImage from 'gatsby-background-image';
// import Link from 'gatsby-link';

import { Clients, El } from './Clients/list';

type Props = {
    className: string;
    bgColor: string;
    waveColor: string;
};

export default function Comp(): React.ReactElement {
    return (
        <section className="text-gray-700 body-font">
            <div className="container px-5 py-5 mx-auto">
                <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2">Clients</h1>
                    <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                </div>

                <div className="grid grid-cols-6 gap-8 py-5">
                    {Clients.map((industry) => (
                        <div className="mb-4">
                            <El key={industry.name} {...industry} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
