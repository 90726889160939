// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import SpeakToAnExpert from '../../../components/page/SpeakToAnExpert';

import CollabImage from '../../../components/Images/infographics/data-virtualization';

import Intro from './components/intro';
import HowCanWeHelp from './components/AreYouPrepared';
import Services from './components/services';
import EnterpriseFunctionConsulting from './components/EnterpriseFunctionConsulting';
import Capabilities from './components/Capabilites';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            style={{
                backgroundColor: 'fff',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="py-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    <Intro />
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: contiguousColor,
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 mx-auto">
                <HowCanWeHelp />
            </div>

            <div className="container px-5 mx-auto">
                <div className="container mx-auto px-6 py-8">
                    <div className="flex flex-col">
                        <div className="h-1 bg-gray-200 rounded overflow-hidden">
                            <div className="w-24 h-full bg-indigo-500"></div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row sm:items-center items-start mx-auto px-6">
                    <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
                        HOW TO START: Modernize your existing business solutions or build new?
                    </h1>
                    <Link
                        to="/services/cloud/modernization"
                        className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                    >
                        Learn More
                    </Link>
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section>
            <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    <CollabImage />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900" textReady={80}>
                        Leverage emerging trends to advantage
                    </h1>

                    <h4 className="text-xl mb-4" textReady={80}>
                        The new reality is that 71% of organizations are in the middle or on the brink of significant
                        disruption. Rapidly emerging trends are the cause of critical disruption for around 70% of
                        businesses.
                    </h4>

                    <p textReady={80}>
                        It's critical to grasp the opportunities afforded by the emergence of game-changing trends.
                    </p>

                    <p textReady={80}>
                        We are the right partners to drive competitive advantange by bringing together business strategy
                        leaders, data scientists, developers, experienced engineers, marketers, researchers and other
                        highly skilled and experienced teams to guide you on your journey to modernization.
                    </p>

                    <p textReady={80}>
                        With our provacative and visionary thought leaders, creative and experienced teams we can help
                        our clients ride the wave of disruption to build scalable and modern solutions to reach
                        leadership positions in the competitive tech landscape.
                    </p>
                </div>
            </div>
        </section>

        <section>
            <div className="container px-4 py-4 mx-auto mb-12">
                <Services />
            </div>
        </section>

        <section>
            <div className="container px-4 py-4 mx-auto">
                <EnterpriseFunctionConsulting />
            </div>
        </section>

        <section
            className="text-gray-700 body-font mt-20 pt-20"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-4 py-4 mx-auto">
                <Capabilities />
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                title="How we can help with application modernization"
                content="We're with you every step along the way of your modernization journey. Whether you need to modernize your existing business solutions or build new hybrid cloud applications, our dedicated experts can help."
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
