// textReady OK
import React from 'react';
import Link from 'gatsby-link';

export default function Comp(): React.ReactElement {
    return (
        <section className="body-font">
            <div className="container flex flex-wrap px-5 py-8 mx-auto items-center">
                <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-300">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2" textReady={70}>
                        Impact Driven Innovation
                    </h1>
                    <p className="leading-relaxed text-base" textReady={80}>
                        Through experience based innovations we apply industry know-how, a rich skill set, and
                        cutting-edge solutions to the challenges faced by modern enterprise.
                    </p>

                    <Link to="/about-us/innovation" className="text-indigo-500 inline-flex items-center mt-4">
                        Learn More
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </Link>
                </div>
                <div className="flex flex-col md:w-1/2 md:pl-12">
                    <h2 className="title-font font-medium text-gray-400 tracking-widest text-sm mb-3">CATEGORIES</h2>
                    <nav className="flex flex-wrap list-none -mb-1 text-gray-400">
                        <li className="lg:w-1/3 mb-1 w-1/2">
                            <Link to="/about-us/innovation" className=" hover:text-gray-800">
                                Research
                            </Link>
                        </li>
                        <li className="lg:w-1/3 mb-1 w-1/2">
                            <a className=" hover:text-gray-800"></a>
                            <Link to="/about-us/innovation" className=" hover:text-gray-800">
                                Growth Investment
                            </Link>
                        </li>
                        <li className="lg:w-1/3 mb-1 w-1/2">
                            <Link to="/about-us/innovation" className=" hover:text-gray-800">
                                Labs
                            </Link>
                        </li>
                        <li className="lg:w-1/3 mb-1 w-1/2">
                            <Link to="/about-us/innovation" className=" hover:text-gray-800">
                                Digital Studios
                            </Link>
                        </li>
                    </nav>
                </div>
            </div>
        </section>
    );
}
