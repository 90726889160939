// Text Ready OK
import React from 'react';

const SecondPage = () => (
    <>
        <div className="flex flex-col text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">MODERNIZATION</h2>
            <h1 className="sm:text-3xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                How can we help with your business strategy?
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl ">
                Our experience and vision spans over a vast array of capabilities, services and products to help
                kickstart your modernization journey.
            </p>
        </div>
        <div className="flex flex-wrap text-lg">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6">
                <p className="leading-relaxed mb-4">Learn how to engage meaningfully with customers, staff and more.</p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <p className="leading-relaxed mb-4">
                    Envision and capture new value from data as a marketable corporate asset.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <p className="leading-relaxed mb-4">
                    Create memorable design experiences that resonate to give rise to new brand value.
                </p>
            </div>
        </div>
    </>
);

export default SecondPage;
