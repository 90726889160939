// Text Ready OK
import React from 'react';
import { Link } from 'gatsby';

import Image1 from '../../../../components/Images/services/CloudApplicationManagement';
import Image2 from '../../../../components/Images/services/CloudApplicationDevelopment';
import Image3 from '../../../../components/Images/services/EnterpriseCloud';

const SecondPage = () => (
    <>
        <div className="text-center">
            <h1 className="text-6xl font-semibold leading-tight mb-12 tracking-tight">Our Capabilities</h1>
        </div>

        <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/3 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                    <Link to="/services/cloud/cloud-application-management">
                        <Image1 />

                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                            Cloud Application Management
                        </h2>
                        <p className="leading-relaxed text-base" textReady={60}>
                            Cloud platforms built on modern infrastructure using microservice architectures let you
                            monitor performance and availability of Kubernetes-managed resources for both virtualized
                            and on-site apps.
                        </p>
                    </Link>
                </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                    <Link to="/services/cloud/cloud-application-development">
                        <Image2 />
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                            Cloud Application Development
                        </h2>
                        <p className="leading-relaxed text-base" textReady={70}>
                            Accelerate innovation with cloud deployment strategies at lower costs and reach faster time
                            to market and maximizw revenue growth with secure and agile multicloud platforms.
                        </p>
                    </Link>
                </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                    <Link to="/services/cloud/enterprise-cloud-strategy">
                        <Image3 />
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4 font-semibold">
                            Cloud Stategy for Enterprise
                        </h2>
                        <p className="leading-relaxed text-base" textReady={70}>
                            Converge business and IT specifications to cloud platforms advisory recommendations and an
                            operating roadmap through functional and business-case focussed design.
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    </>
);

export default SecondPage;
