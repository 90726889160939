// textReady OK
import React from 'react';

export default function Comp({ title, content }: { title: string; content: string }): React.ReactElement {
    return (
        <section className="text-white body-font">
            <div className="container px-5  mx-auto flex flex-wrap items-center">
                <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                    <h1 className="title-font font-bold text-3xl">{title}</h1>
                    <p className="leading-relaxed mt-4 text-lg">{content}</p>
                </div>
                <div className="lg:w-2/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                    <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Speak to an Expert</h2>
                    <input
                        className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4"
                        placeholder="Full Name"
                        type="text"
                    />
                    <input
                        className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4"
                        placeholder="Email"
                        type="email"
                    />
                    <button className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                        Get in Touch
                    </button>
                    <p className="text-xs text-gray-500 mt-3">We will never share your details</p>
                </div>
            </div>
        </section>
    );
}
