// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import Intro from './components/intro';
import Stats from './components/stats';
import AreYouPrepared from './components/AreYouPrepared';
import Terms from './components/Terms';
import Capabilities from './components/Capabilites';

import CollabImage from '../../../components/Images/contextual/Collab';
import GlobeIdeaImage from '../../../components/Images/infographics/globe-idea';

import TickedList from '../../../components/page/Lists/Ticked';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />
        <section
            style={{
                backgroundColor: 'fff',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="py-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    <Intro />
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: contiguousColor,
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 mx-auto">
                <AreYouPrepared />

                <div className="container mx-auto px-4 pt-5">
                    <div className="flex flex-col">
                        <div className="h-1 bg-gray-200 rounded overflow-hidden">
                            <div className="w-24 h-full bg-indigo-500"></div>
                        </div>
                    </div>
                </div>
                <section className="text-gray-700 body-font mt-5">
                    <div className="container px-5 mx-auto">
                        <div className="flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                            <h1
                                className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900"
                                textReady={0}
                            >
                                HOW TO START: Modernize your existing application or build new?
                            </h1>
                            <Link
                                to="/services/cloud/modernization"
                                className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                            >
                                Learn More
                            </Link>
                        </div>
                    </div>
                </section>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section>
            <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                    <GlobeIdeaImage />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900" textReady={60}>
                        Enhance legacy IT to accelerate digital modernization
                    </h1>

                    <h4 className="text-xl mb-4" textReady={80}>
                        Transform and enhance at speed and scale existing IT to agile and flexible cloud-enabled and
                        open platforms. Industry leaders are embracing cloud solutions to modernize And extend
                        operations and gain the competitive edge while discovering new opportunities with AI driven data
                        analytics and other emerging trends.
                    </h4>

                    <p textReady={80}>
                        We are the right people who can partner and consult with you to enable your cloud and digital
                        initiatives and help in transforming your business operations and technology ecosystems. At all
                        stages of your growth from inception to expansion, in all verticals of industry we can help to
                        master innovation and move to the cloud with confidence.
                    </p>
                </div>
            </div>

            <section className="text-gray-700 body-font">
                <div className="py-4 ">
                    <div className="flex items-center lg:w-3/5 mx-auto  border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                        <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                            <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="sm:w-16 sm:h-16 w-10 h-10"
                                viewBox="0 0 24 24"
                            >
                                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                            </svg>
                        </div>
                        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                            <h2 className="text-gray-900 text-2xl title-font font-medium mb-2">
                                Accelerate innovation by building cloud native
                            </h2>
                            <p className="leading-relaxed text-base font-semibold mb-5" textReady={80}>
                                Tried and proven development methodologies to ensure your transformation to cloud-native
                                occurs with confidence at speed and scale. Build with faster time to market and iterate
                                at lower costs and with greater ROI. We can help you:
                            </p>
                            <TickedList
                                options={[
                                    'Build open, secure, hybrid multicloud platforms with cloud native solutions',
                                    // 'Co-create and deploy cloud native applications built for open, secure, hybrid multicloud platforms',
                                    // 'Build new applications with microservices, containers/kubernetes and DevSecOps tools',
                                    'Create, iterate and deploy new applications with microservice architectures, virtualization, containers and DevOps',
                                ]}
                            />

                            <Link
                                to="/services/cloud/cloud-application-development"
                                className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                            >
                                Learn more
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center lg:w-3/5 mx-auto  border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                        <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                            <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="sm:w-16 sm:h-16 w-10 h-10"
                                viewBox="0 0 24 24"
                            >
                                <circle cx="6" cy="6" r="3"></circle>
                                <circle cx="6" cy="18" r="3"></circle>
                                <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                            </svg>
                        </div>

                        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                            <h2 className="text-gray-900 text-2xl title-font font-medium mb-2">
                                Modernize applications to increase agility
                            </h2>
                            <p className="leading-relaxed text-base font-semibold mb-5" textReady={80}>
                                Organizations that invest in the moderniztion of exiting legacy IT and operations see
                                greater growth and revenue. We can help you:
                            </p>
                            <TickedList
                                options={[
                                    // 'Define and build application and infrastructure strategy and architecture',
                                    'Design, build and deploy cloud-hybrid application, IaaS strategy and PaaS architectures',
                                    // 'Modernize applications with microservices, containers / Kubernetes and DevSecOps tools',
                                    'Transform and enhance legacy applications with cloud AI services, microservices, containers / Kubernetes and DevSecOps tools',
                                ]}
                            />
                            <Link
                                to="/services/cloud/modernization"
                                className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                            >
                                Learn more
                            </Link>
                        </div>
                    </div>

                    <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
                        <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                            <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="sm:w-16 sm:h-16 w-10 h-10"
                                viewBox="0 0 24 24"
                            >
                                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </div>
                        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                            <h2 className="text-gray-900 text-2xl title-font font-medium mb-2">
                                Managed Multicloud Services
                            </h2>
                            <p className="leading-relaxed text-base font-semibold mb-5">
                                Enterprises that offload multicloud management can focus on business development and
                                experience greater growth and profits. We can help you with:
                            </p>

                            <TickedList
                                options={[
                                    'Simplify IT platforms and ecosystems management',
                                    'Cross-platform and provider management and integrations',
                                    'Cloud Security, compliance and DevSecOps',
                                ]}
                            />

                            <Link
                                to="/services/cloud/cloud-application-management"
                                className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                            >
                                Learn more
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section
            className="text-gray-700 body-font mt-20 pt-20"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <Capabilities />
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
            className="text-white"
        >
            <Stats />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <div className="container px-5 py-5 mx-auto flex flex-wrap">
            <section className="text-gray-700 body-font">
                <div className="container px-5 py-4 mx-auto">
                    <Terms />
                </div>
            </section>
        </div>
    </Layout>
);

export default SecondPage;
