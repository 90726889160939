import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

// import TravelIcon from './Icons/Travel';
import TravelIcon from '../../assets/icons/airplane.svg';
import BankIcon from '../../assets/icons/bank.svg';
import EuroIcon from '../../assets/icons/euro.svg';
import FactoryIcon from '../../assets/icons/factory.svg';
import CommunicationsIcon from '../../assets/icons/internet.svg';
import SoftwareIcon from '../../assets/icons/line-graph.svg';
import MortarIcon from '../../assets/icons/mortarboard.svg';
import RetailIcon from '../../assets/icons/shopping-cart.svg';
import WheelchairIcon from '../../assets/icons/wheelchair.svg';

const BI = styled('div')`
    background-color: rgba(19, 96, 239, 0.02);
    ${tw`p-6 rounded-lg`}

    p {
        color: #8090b5;
    }
`;

export const El = ({ Icon, title, content, slug }: { Icon: string; title: string; content: string; slug: string }) => {
    return (
        <div className="xl:w-1/3 md:w-1/2 p-4">
            {/* <Link to={slug}> */}
            <BI>
                <div className="relative flex items-center justify-center">
                    <div className="w-10 h-10 inline-flex items-center rounded-full   justify-center">
                        <Icon fill={'#4a5568'} width="100" height="100" />
                    </div>
                    <h2 className="ml-4 text-xl inline-flex font-medium title-font">{title}</h2>
                </div>

                <p className="leading-relaxed text-base">{content}</p>
            </BI>
            {/* </Link> */}
        </div>
    );
};

export const Industries = [
    {
        Icon: WheelchairIcon,
        title: `Health consulting`,
        // content: "The need for hyper-collaboration in healthcare comes with challenges. We make integration easier by modernizing your IT ecosystem for a stronger core that delivers growth."
        content: `We are passionate about solving the issues that come with enabling cooperation between healthcare professionals and institutions. We facilitate integration of existing IT infrastructures to lay solid foundations that bring better tools for care.`,
        slug: '/industries/health',
    },

    {
        Icon: SoftwareIcon,
        title: 'Software & Platforms',
        // content: `To thrive in an industry that relies on disruption, and edge out the competition, you need to rev up evolution and harness an acceleration advantage.`,
        // content: `Companies like yours have revolutionized information delivery. To maintain and sharpen your digital edge, turn to our experts.`
        content: `Maintain your digital advantage, evolve your digital edge, and thrive in a highly-competitive industry that relies on disruption.`,
        slug: '/industries/software',
    },

    {
        Icon: CommunicationsIcon,
        title: 'Communications & Media',
        // content: `Shift to experience-led open platform models to become unstoppable providers of next-generation IP services and immersive customer experiences..`,
        // content: `Engineer personalized CX and streamline operations to open new opportunities for monetizing insights and delivering bottom-line results.
        content: `Build next-generation personalized Customer Experiences (CX) to open new opportunities in experience-led platforms for monetizing insights and delivering value.`,
        slug: '/industries/communications',
    },

    {
        Icon: MortarIcon,
        title: 'Education',
        // content: ` In the face of accelerating change, we help government organisations to embrace innovation and transform fast-to operate smarter, achieve more and improve services.`,
        // content: ``From online tools to in-classroom courses, we offer the latest digital solutions to keep challenging the learning experience.`
        content: `We help education institutions and schools embrace the latest digital solutions and quikly transform to offer modern, smarter more efficient learning experiences`,
        slug: '/industries/education',
    },

    {
        Icon: TravelIcon,
        title: 'Travel & Hospitality Consulting',
        // content: `In an era of unprecedented competition, market volatility and disruptive business models how can travel companies keep pace to become real-time?`,
        // content: `Recovery and growth are within reach for those able to provide superior experiences and digital efficiencies. Engage with us for strategic solutions based on what comes next`,
        content: `Learn how we can assist in the recovery and growth in an industry negatively impacted by unprecendented disruption brought on by the COVID pandemic. Engage with us for strategic solutions on Recovery and growth.`,
        slug: '/industries/travel',
    },

    {
        Icon: RetailIcon,
        title: 'Retail Consulting',
        // content: `How can retailers deliver their purpose and remain profitable? Responsible Retail—good for consumers, employees, partners, investors and our planet.`,
        // content: `Our innovations in supply chain management, merchandising, e-commerce and mobility help retailers meet fast-changing customer needs.`
        content: `Our advanced solutions in e-commerce, mobility and suplly chain help retailers deliver purpose and remain profitable while adapting rapidly changing customer needs.`,
        slug: '/industries/retail',
    },

    {
        Icon: FactoryIcon,
        title: 'Energy Consulting',
        // content: `The future of oil and gas? Safer, smarter and digital. From upstream to trading and refining to marketing, we help companies across the value chain.`,
        // content: `Retooling for the digital age, oil and gas providers can deliver more energy options, reduce costs and increase customer satisfaction.`
        content: `The future of energy? Safer, smarter and greener with the help digital innovation. Energy providers more efficient and responsible options, while reducing costs and increasing customer satisfaction and protecting our environments.`,
        slug: '/industries/energy',
    },

    {
        Icon: BankIcon,
        title: 'Capital Markets',
        // content: `We help investment banks, asset and wealth managers, and exchanges prepare for the digital future.`,
        // content: `Streamline inefficient processes with strategies and solutions that grow your business and keep customers at the center of your organization.
        content: `We help investment banks, asset and wealth managers, and exchanges streamline inefficient processes and prepare for digital future with strategic solutions and customer-centric models .`,
        slug: '/industries/capital-markets',
    },

    {
        Icon: EuroIcon,
        title: 'Public Service Consulting',
        // content: `e help government organisations to embrace innovation and transform fast-to operate smarter, achieve more and improve services.`,
        content: `We help government organisations streamline inefficient processes by embracing responsible innovation and quickly transform to operate smarter and better serve the community.`,
        slug: '/industries/public-service',
    },
];
