// Gatsby supports TypeScript natively!
import React from 'react';

const SecondPage = ({
    tags,
    title,
    subtitle,
    Image,
}: {
    tags: string | null;
    title: string;
    subtitle: string;
    Image?: React.FC | null;
}): React.ReactElement => (
    <>
        {tags && <h4 className="text-xl mb-4 font-light">{tags}</h4>}
        <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight">{title}</h1>
        <div className="text-2xl font-light pb-5 tracking-normal tracking-tight">
            <p>{subtitle}</p>
        </div>
        {Image && (
            <div className="lg:w-2/3 mx-auto mt-6">
                <Image />
            </div>
        )}
    </>
);

export default SecondPage;
