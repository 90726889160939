// Text Ready OK
import React from 'react';

import CloudServiceImage from '../../../../components/Images/infographics/data-statistics';

const SecondPage = () => (
    <>
        <h4 className="text-xl mb-4 font-light">
            Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity
        </h4>
        <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight">
            Business Process & AI Driven Workflows
        </h1>
        <div className="text-2xl font-light pb-5 tracking-normal tracking-tight" textReady={80}>
            <p>Discover how business operations driven by intelligent workflows and AI informed data empower teams.</p>
        </div>
        <div className="lg:w-2/3 mx-auto">
            <CloudServiceImage />
        </div>
    </>
);

export default SecondPage;
