// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';

import Intro from '../../../../components/page/Intro/Intro';
import SpeakToAnExpert from '../../../../components/page/SpeakToAnExpert';

import CloudServiceImage from '../../../../components/Images/infographics/internet-of-things';

import Capabilities from './components/capabilities';
import HelpfulCloudNative from './components/HelpfulCloudNative';

const contiguousColor = '#ececec';
const darkBlue = '#060927';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />
        <section className="">
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4 mb-8">
                    <Intro
                        title="Innovation at the Warp Speed with Cloud Native"
                        subtitle="Build quickly. Deploy anywhere. Iterate with conficence."
                        Image={() => <CloudServiceImage />}
                    />
                </div>

                <div className="flex w-full mb-20 mt-10 flex-wrap">
                    <h1
                        className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4"
                        textReady={80}
                    >
                        We help businesses in the modernization of the applications to becoming AI powered cloud
                        powerhouses
                    </h1>
                    <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-lg" textReady={80}>
                        We work with you to design tailored solutions for your unique business requirements. Our
                        experienced team members leverage state-of-the-art technologies and methodolodies to modernize
                        your business into a AI enabled hybrid cloud business. We continue to provide tangible returns
                        for our clients and the society and audiences they serve.
                    </p>
                </div>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: darkBlue,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 pt-20 mx-auto text-white">
                <HelpfulCloudNative />
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: contiguousColor,

                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <Capabilities />
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
                >
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                title="How we can help with application modernization"
                content="We're with you every step along the way of your modernization journey. Whether you need to modernize your existing business solutions or build new hybrid cloud applications, our dedicated experts can help."
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
