// Text Ready 80
import React from 'react';

import CloudServiceImage from '../../../../components/Images/infographics/services-cloud';

const SecondPage = () => (
    <>
        <div className="text-center">
            <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight">F.A.Q.</h1>
        </div>

        <div className="flex flex-wrap -m-4">
            <div className="p-4 lg:w-1/3">
                <div className="h-full bg-gray-200 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">CATEGORY</h2>
                    <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3" textReady={80}>
                        What is cloud adoption?
                    </h1>
                    <p className="leading-relaxed mb-3" textReady={80}>
                        Cloud adoption is the process for implementing digital transformation. Cloud adoption for
                        business requires a strategic execution plan that embraces existing and upcoming technology.
                        This guarantees success, and ensures the right team, capabilities and management system your
                        enterprise solutions require.
                    </p>
                </div>
            </div>
            <div className="p-4 lg:w-1/3">
                <div className="h-full bg-gray-200 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">CATEGORY</h2>
                    <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                        What is cloud strategy consulting?
                    </h1>
                    <p className="leading-relaxed mb-3" textReady={80}>
                        Cloud strategy consulting is the convergince of our experienced teams, tools and trusted
                        solutions to develop business-focussed cloud strategies and roadmap implementation to advise
                        clients in modernizing their business operations.
                    </p>
                </div>
            </div>
            <div className="p-4 lg:w-1/3">
                <div className="h-full bg-gray-200 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">CATEGORY</h2>
                    <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                        What is a cloud strategy?
                    </h1>
                    <p className="leading-relaxed mb-3">
                        A cloud strategy clearly identifies your business requirements with a plan of action that
                        implements your desired outcomes. Having a cloud strategy will enable you to rapidly realized
                        your journey to cloud modernization.
                    </p>
                </div>
            </div>
        </div>
    </>
);

export default SecondPage;
