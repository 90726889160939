// Text Ready OK
import React from 'react';

import CloudStrategyAndAdoptionImage from '../images/CloudStrategyAndAdoptionImage';
import BusinessAutomationImage from '../../../../components/Images/services/ExtremeAutomation';
import BusinessIntelligenceAndAnalyticsConsultingImage from '../images/BusinessIntelligenceAndAnalyticsConsultingImage ';

const SecondPage = () => (
    <>
        <div className="text-center">
            <h1 className="text-3xl font-semibold leading-tight mb-12 tracking-tight">
                Enhance Strategy Through Technology
            </h1>
        </div>

        <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/3 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                    <CloudStrategyAndAdoptionImage />
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                        Cloud Strategy and Adoption
                    </h2>
                    <p className="leading-relaxed text-base">
                        Realize business value with consulting services for cloud adoption.
                    </p>
                </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                    <BusinessAutomationImage />
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                        Business Automation
                    </h2>
                    <p className="leading-relaxed text-base">
                        Leverage modern automation solutions to enable your business strategy.
                    </p>
                </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4">
                <div className="bg-gray-100 p-6 rounded-lg">
                    <BusinessIntelligenceAndAnalyticsConsultingImage />
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4 font-semibold">
                        Business Intelligence and Analytics Advisory
                    </h2>
                    <p className="leading-relaxed text-base">
                        Bringing together business intelligence and analytics to achieve your business requirements.
                    </p>
                </div>
            </div>
        </div>
    </>
);

export default SecondPage;
