// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-components-digital-at-scale-tsx": () => import("./../src/pages/about-us/components/DigitalAtScale.tsx" /* webpackChunkName: "component---src-pages-about-us-components-digital-at-scale-tsx" */),
  "component---src-pages-about-us-components-intro-tsx": () => import("./../src/pages/about-us/components/intro.tsx" /* webpackChunkName: "component---src-pages-about-us-components-intro-tsx" */),
  "component---src-pages-about-us-components-our-company-tsx": () => import("./../src/pages/about-us/components/OurCompany.tsx" /* webpackChunkName: "component---src-pages-about-us-components-our-company-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-innovation-components-intro-tsx": () => import("./../src/pages/about-us/innovation/components/intro.tsx" /* webpackChunkName: "component---src-pages-about-us-innovation-components-intro-tsx" */),
  "component---src-pages-about-us-innovation-index-tsx": () => import("./../src/pages/about-us/innovation/index.tsx" /* webpackChunkName: "component---src-pages-about-us-innovation-index-tsx" */),
  "component---src-pages-all-files-tsx": () => import("./../src/pages/all-files.tsx" /* webpackChunkName: "component---src-pages-all-files-tsx" */),
  "component---src-pages-all-markdown-tsx": () => import("./../src/pages/all-markdown.tsx" /* webpackChunkName: "component---src-pages-all-markdown-tsx" */),
  "component---src-pages-contact-us-components-options-tsx": () => import("./../src/pages/contact-us/components/options.tsx" /* webpackChunkName: "component---src-pages-contact-us-components-options-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-components-intro-tsx": () => import("./../src/pages/industries/components/intro.tsx" /* webpackChunkName: "component---src-pages-industries-components-intro-tsx" */),
  "component---src-pages-industries-index-tsx": () => import("./../src/pages/industries/index.tsx" /* webpackChunkName: "component---src-pages-industries-index-tsx" */),
  "component---src-pages-services-business-operations-components-business-operations-outcome-tsx": () => import("./../src/pages/services/business-operations/components/BusinessOperationsOutcome.tsx" /* webpackChunkName: "component---src-pages-services-business-operations-components-business-operations-outcome-tsx" */),
  "component---src-pages-services-business-operations-components-intro-tsx": () => import("./../src/pages/services/business-operations/components/intro.tsx" /* webpackChunkName: "component---src-pages-services-business-operations-components-intro-tsx" */),
  "component---src-pages-services-business-operations-components-services-tsx": () => import("./../src/pages/services/business-operations/components/services.tsx" /* webpackChunkName: "component---src-pages-services-business-operations-components-services-tsx" */),
  "component---src-pages-services-business-operations-index-tsx": () => import("./../src/pages/services/business-operations/index.tsx" /* webpackChunkName: "component---src-pages-services-business-operations-index-tsx" */),
  "component---src-pages-services-business-operations-outsourcing-components-capabilities-tsx": () => import("./../src/pages/services/business-operations/outsourcing/components/capabilities.tsx" /* webpackChunkName: "component---src-pages-services-business-operations-outsourcing-components-capabilities-tsx" */),
  "component---src-pages-services-business-operations-outsourcing-index-tsx": () => import("./../src/pages/services/business-operations/outsourcing/index.tsx" /* webpackChunkName: "component---src-pages-services-business-operations-outsourcing-index-tsx" */),
  "component---src-pages-services-business-strategy-components-are-you-prepared-tsx": () => import("./../src/pages/services/business-strategy/components/AreYouPrepared.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-components-are-you-prepared-tsx" */),
  "component---src-pages-services-business-strategy-components-capabilites-tsx": () => import("./../src/pages/services/business-strategy/components/Capabilites.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-components-capabilites-tsx" */),
  "component---src-pages-services-business-strategy-components-enterprise-function-consulting-tsx": () => import("./../src/pages/services/business-strategy/components/EnterpriseFunctionConsulting.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-components-enterprise-function-consulting-tsx" */),
  "component---src-pages-services-business-strategy-components-intro-tsx": () => import("./../src/pages/services/business-strategy/components/intro.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-components-intro-tsx" */),
  "component---src-pages-services-business-strategy-components-services-tsx": () => import("./../src/pages/services/business-strategy/components/services.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-components-services-tsx" */),
  "component---src-pages-services-business-strategy-images-business-intelligence-and-analytics-consulting-image-tsx": () => import("./../src/pages/services/business-strategy/images/BusinessIntelligenceAndAnalyticsConsultingImage .tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-images-business-intelligence-and-analytics-consulting-image-tsx" */),
  "component---src-pages-services-business-strategy-images-cloud-strategy-and-adoption-image-tsx": () => import("./../src/pages/services/business-strategy/images/CloudStrategyAndAdoptionImage.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-images-cloud-strategy-and-adoption-image-tsx" */),
  "component---src-pages-services-business-strategy-index-tsx": () => import("./../src/pages/services/business-strategy/index.tsx" /* webpackChunkName: "component---src-pages-services-business-strategy-index-tsx" */),
  "component---src-pages-services-cloud-cloud-application-development-components-capabilities-tsx": () => import("./../src/pages/services/cloud/cloud-application-development/components/capabilities.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-application-development-components-capabilities-tsx" */),
  "component---src-pages-services-cloud-cloud-application-development-components-helpful-cloud-native-tsx": () => import("./../src/pages/services/cloud/cloud-application-development/components/HelpfulCloudNative.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-application-development-components-helpful-cloud-native-tsx" */),
  "component---src-pages-services-cloud-cloud-application-development-index-tsx": () => import("./../src/pages/services/cloud/cloud-application-development/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-application-development-index-tsx" */),
  "component---src-pages-services-cloud-cloud-application-management-components-capabilities-tsx": () => import("./../src/pages/services/cloud/cloud-application-management/components/capabilities.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-application-management-components-capabilities-tsx" */),
  "component---src-pages-services-cloud-cloud-application-management-components-let-us-manage-you-app-tsx": () => import("./../src/pages/services/cloud/cloud-application-management/components/LetUsManageYouApp.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-application-management-components-let-us-manage-you-app-tsx" */),
  "component---src-pages-services-cloud-cloud-application-management-index-tsx": () => import("./../src/pages/services/cloud/cloud-application-management/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-application-management-index-tsx" */),
  "component---src-pages-services-cloud-cloud-native-index-tsx": () => import("./../src/pages/services/cloud/cloud-native/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-cloud-native-index-tsx" */),
  "component---src-pages-services-cloud-components-are-you-prepared-tsx": () => import("./../src/pages/services/cloud/components/AreYouPrepared.tsx" /* webpackChunkName: "component---src-pages-services-cloud-components-are-you-prepared-tsx" */),
  "component---src-pages-services-cloud-components-capabilites-tsx": () => import("./../src/pages/services/cloud/components/Capabilites.tsx" /* webpackChunkName: "component---src-pages-services-cloud-components-capabilites-tsx" */),
  "component---src-pages-services-cloud-components-intro-tsx": () => import("./../src/pages/services/cloud/components/intro.tsx" /* webpackChunkName: "component---src-pages-services-cloud-components-intro-tsx" */),
  "component---src-pages-services-cloud-components-stats-tsx": () => import("./../src/pages/services/cloud/components/stats.tsx" /* webpackChunkName: "component---src-pages-services-cloud-components-stats-tsx" */),
  "component---src-pages-services-cloud-components-terms-tsx": () => import("./../src/pages/services/cloud/components/Terms.tsx" /* webpackChunkName: "component---src-pages-services-cloud-components-terms-tsx" */),
  "component---src-pages-services-cloud-enterprise-cloud-strategy-components-capabilities-tsx": () => import("./../src/pages/services/cloud/enterprise-cloud-strategy/components/capabilities.tsx" /* webpackChunkName: "component---src-pages-services-cloud-enterprise-cloud-strategy-components-capabilities-tsx" */),
  "component---src-pages-services-cloud-enterprise-cloud-strategy-components-deffine-your-strategy-tsx": () => import("./../src/pages/services/cloud/enterprise-cloud-strategy/components/DeffineYourStrategy.tsx" /* webpackChunkName: "component---src-pages-services-cloud-enterprise-cloud-strategy-components-deffine-your-strategy-tsx" */),
  "component---src-pages-services-cloud-enterprise-cloud-strategy-index-tsx": () => import("./../src/pages/services/cloud/enterprise-cloud-strategy/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-enterprise-cloud-strategy-index-tsx" */),
  "component---src-pages-services-cloud-index-tsx": () => import("./../src/pages/services/cloud/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-index-tsx" */),
  "component---src-pages-services-cloud-modernization-components-getting-started-tsx": () => import("./../src/pages/services/cloud/modernization/components/GettingStarted.tsx" /* webpackChunkName: "component---src-pages-services-cloud-modernization-components-getting-started-tsx" */),
  "component---src-pages-services-cloud-modernization-components-maximise-application-migration-tsx": () => import("./../src/pages/services/cloud/modernization/components/MaximiseApplicationMigration.tsx" /* webpackChunkName: "component---src-pages-services-cloud-modernization-components-maximise-application-migration-tsx" */),
  "component---src-pages-services-cloud-modernization-index-tsx": () => import("./../src/pages/services/cloud/modernization/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-modernization-index-tsx" */),
  "component---src-pages-services-components-intro-tsx": () => import("./../src/pages/services/components/intro.tsx" /* webpackChunkName: "component---src-pages-services-components-intro-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-technology-support-components-capabilities-tsx": () => import("./../src/pages/services/technology-support/components/capabilities.tsx" /* webpackChunkName: "component---src-pages-services-technology-support-components-capabilities-tsx" */),
  "component---src-pages-services-technology-support-components-intro-tsx": () => import("./../src/pages/services/technology-support/components/intro.tsx" /* webpackChunkName: "component---src-pages-services-technology-support-components-intro-tsx" */),
  "component---src-pages-services-technology-support-components-long-term-benefits-tsx": () => import("./../src/pages/services/technology-support/components/LongTermBenefits.tsx" /* webpackChunkName: "component---src-pages-services-technology-support-components-long-term-benefits-tsx" */),
  "component---src-pages-services-technology-support-components-support-via-innovation-tsx": () => import("./../src/pages/services/technology-support/components/SupportViaInnovation.tsx" /* webpackChunkName: "component---src-pages-services-technology-support-components-support-via-innovation-tsx" */),
  "component---src-pages-services-technology-support-index-tsx": () => import("./../src/pages/services/technology-support/index.tsx" /* webpackChunkName: "component---src-pages-services-technology-support-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

