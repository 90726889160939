// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import Intro from './components/intro';
import BusinessOperationsOutcome from './components/BusinessOperationsOutcome';
import Services from './components/services';

import CollabImage from '../../../components/Images/infographics/data-storage';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            style={{
                backgroundColor: 'fff',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="py-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    <Intro />
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: contiguousColor,
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    <CollabImage />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900" textReady={80}>
                        Intelligent workflows for the AI Ready Enterprise
                    </h1>

                    <h4 className="text-xl mb-4" textReady={80}>
                        We help to reimagine operational and functional processes transform your business into an AI
                        enabled enterprise. Intelligent workflows help transform your existing solutions and make your
                        operations run more efficiently while reducing cost.
                    </h4>

                    <p textReady={80}>
                        We transform operational workflows to fit ever changing industry landscapes, markets and
                        eco-systems by leveraging AI, automation and cloud technologies.
                    </p>
                </div>
            </div>

            <div className="container px-5 mx-auto">
                <BusinessOperationsOutcome />
            </div>

            <div className="container mx-auto px-4 pt-20">
                <div className="flex flex-col">
                    <div className="h-1 bg-gray-200 rounded overflow-hidden">
                        <div className="w-24 h-full bg-indigo-500"></div>
                    </div>
                </div>
            </div>

            <section className="text-gray-700 body-font mt-5">
                <div className="container px-5 mx-auto">
                    <div className="flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900" textReady={60}>
                            Outsource Business Operations for continuous and resilient businsses
                        </h1>
                        <Link
                            to="/services/business-operations/outsourcing"
                            className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                        >
                            Learn More
                        </Link>
                    </div>
                </div>
            </section>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section>
            <div className="container py-4 mx-auto">
                <Services />
            </div>
        </section>
    </Layout>
);

export default SecondPage;
