// textReady OK
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Link } from 'gatsby';

import Layout from '../components/HomeLayout';
import SEO from '../components/seo';

import Hero from '../components/HomeHero';
import Innovation from '../components/Innovation';
import Industries from '../components/Industries';
import Clients from '../components/Clients';
// import MoreCoolStuff from '../components/MoreCoolStuff';
import ServicesIBM from '../components/ServicesIBM';
// import WarmWetRag from '../components/WarmWetRag';
import CAction from '../components/CAction';
import { NAME } from '../constants';

const contiguousColor = '#060927';

const IndexPage = (): React.ReactElement => (
    <>
        <SEO
            title={`${NAME} - Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity | Cloud Native | Hybrid Cloud`}
        />
        <Layout>
            <section>
                <div className="relative">
                    <Hero className="flex relative" bgColor="#1360ef" waveColor={'#fff'}>
                        <div className="container mx-auto text-white text-center">
                            <h4 className="text-xl mb-4 font-light">
                                Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity
                            </h4>
                            <h1 textReady={'90%'} className="text-6xl font-semibold leading-tight mb-3 tracking-tight">
                                The right team, finding solutions through insight and experience.
                            </h1>
                            <div className="text-2xl font-light pb-5 tracking-normal tracking-tight">
                                <p textReady={'90%'}>
                                    We create complex digital products and innovative services by integrating leading
                                    technologies such as data science and analytics, digital solutions, engineering, and
                                    cybersecurity.
                                </p>
                            </div>

                            <Link
                                to="/about-us/innovation"
                                style={{ backgroundColor: contiguousColor, borderColor: contiguousColor }}
                                className="mr-3 text-white border py-2 px-6 focus:outline-none rounded text-lg"
                            >
                                Learn More
                            </Link>
                            <Link
                                to="/contact-us"
                                className="border border-white-500 text-white py-2 px-5 rounded text-lg"
                            >
                                Contact Us
                            </Link>
                        </div>
                    </Hero>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: 'fff',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <ServicesIBM />
                {/* <div className="container px-5 mx-auto">
                    <div className="container mx-auto px-6 py-8">
                        <div className="flex flex-col">
                            <div className="h-1 bg-gray-200 rounded overflow-hidden">
                                <div className="w-24 h-full bg-indigo-500"></div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:items-center items-start mx-auto px-6">
                        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
                            HOW TO START: Modernize your existing application or build new ?
                        </h1>
                        <Link
                            to="/services/cloud/modernization"
                            className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                        >
                            Learn More
                        </Link>
                    </div>
                </div> */}
                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: contiguousColor }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: contiguousColor,
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                {/* <Services /> */}

                <div className="container mx-auto px-4 pt-5">
                    <div className="flex flex-col">
                        <div className="h-1 bg-gray-200 rounded overflow-hidden">
                            <div className="w-24 h-full bg-indigo-500"></div>
                        </div>
                    </div>
                </div>

                <div className="text-gray-300">
                    <Innovation />
                </div>

                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#fff' }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: 'fff',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <Industries />

                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#1360ef' }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: '#1360ef',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <CAction />
                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#fff' }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section style={{ backgroundColor: '#fff' }}>
                {/* <MoreCoolStuff /> */}

                <Clients />
            </section>
        </Layout>
    </>
);

export default IndexPage;
