// Text Ready  OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import SecurityIcon from '../../../../../assets/icons/security.svg';
import VendorAgnosticIcon from '../../../../../assets/icons/business-agility.svg';
import AccelerateIcon from '../../../../../assets/icons/accelarete2.svg';

const SecondPage = () => (
    <div>
        <div className="text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
                Three pillars to optimize application migration
            </h1>
            {/* <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice
                poutine, ramps microdosing banh mi pug.
            </p> */}
            <div className="flex mt-6 justify-center">
                <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
            </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <SecurityIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Secure Containers</h2>
                    <p className="leading-relaxed text-base">
                        Rapidly adopt Open-source cloud containers, APIs and microservice patterns vetted for speed and
                        simplicity.
                    </p>
                </div>
            </div>
            <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <VendorAgnosticIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Vendor Agnostic</h2>
                    <p className="leading-relaxed text-base">
                        Essential and critical business operations need open and secure clouds with solutions that can
                        run on all platforms and without vendor lock-in.
                    </p>
                </div>
            </div>
            <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <AccelerateIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Accelerate Transformation</h2>
                    <p className="leading-relaxed text-base">
                        Businesses require simplified management and a foundation for innovation to keep up with ever
                        evolving digital landscapes.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default SecondPage;
