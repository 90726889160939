// Text Ready OK
import React from 'react';

const SecondPage = () => (
    <>
        <div className="flex flex-col text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
                THE AI ENABLED ENTERPRISE
            </h2>
            <h1 className="sm:text-3xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                Business Process & Intelligent Workflows
            </h1>
        </div>
        <div className="flex flex-wrap text-lg">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6">
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                    Transform Logistical Supply Chains
                </h2>
                <p className="leading-relaxed mb-4" textReady={80}>
                    AI enabled systems can schedule every point across the workflow or the supply chain.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                    Personalized Customer Experiences
                </h2>
                <p className="leading-relaxed mb-4" textReady={70}>
                    Utilize AI to deliver personal, meaningful and responsible experiences tailored to a customer's
                    preferences.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h2 className="text-lg text-gray-900 font-medium title-font mb-4  font-semibold">
                    Modernize Business Processes
                </h2>
                <p className="leading-relaxed mb-4" textReady={60}>
                    Modernize your business processes by transforming your end-to-end operations.
                </p>
            </div>
        </div>
    </>
);

export default SecondPage;
