// Text Ready OK
import React from 'react';

// import CollabImage from '../Images/Collab';

import NetworkImage from '../Images/services/Network';
// import CybersecurityImage from '../ServicesImages/Cybersecurity';
import TechImage from '../Images/services/Technology';
import ResiliencyImage from '../Images/services/Resiliency';
import CloudImage from '../Images/services/Cloud';
import BPOImage from '../Images/services/BusinessProcessAndOperations';
import DAIImage from '../Images/services/DataAndAI';
import { Link } from 'gatsby';

function ServiceBlock({ title, Image }: { title: string; Image: React.ReactElement }): React.ReactElement {
    return (
        <div className="relative">
            <Image />
            <div
                className="absolute z-10 bottom-0 p-5 w-full"
                style={{
                    background:
                        'rgba(0, 0, 0, 0) linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%) no-repeat scroll 0 0',
                }}
            >
                <div className="text-white font-semibold text-xl">
                    <h4>{title}</h4>
                </div>
            </div>
        </div>
    );
}

export default function Comp(): React.ReactElement {
    return (
        <section className="text-gray-700 body-font">
            {/* <Innovation /> */}
            <div className="container px-5 py-5 mx-auto flex flex-wrap">
                <div className="flex w-full mb-20 flex-wrap">
                    <h1
                        className="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4"
                        textReady={'70%'}
                    >
                        We help businesses integrate and leverage the cloud in their evolution to becoming cognitive
                        enterprises
                    </h1>
                    <div className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">
                        <p className="mb-2" textReady={'80%'}>
                            We provide continuous opportunity and value for our partners to make a difference in the
                            interconnected world.
                        </p>

                        <p textReady={'80%'}>
                            We're the right people who invest in innovative technologies and integrated services to
                            transform your business into a cognitive enterprise.
                        </p>
                    </div>
                </div>

                <div className="flex w-full flex-wrap md:-m-2 -m-1">
                    <div className="flex flex-wrap w-1/2">
                        <div className="md:p-2 p-1 w-1/2">
                            <Link to="/services/cloud">
                                <ServiceBlock title="Cloud Services" Image={(): React.ReactElement => <CloudImage />} />
                            </Link>
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <Link to="/services/technology-support">
                                <ServiceBlock
                                    title="Technology Support"
                                    Image={(): React.ReactElement => <TechImage />}
                                />
                            </Link>
                        </div>

                        <div className="md:p-2 p-1 w-full">
                            <Link to="/services/business-operations">
                                <ServiceBlock
                                    title="Business Process & Operations"
                                    Image={(): React.ReactElement => <ResiliencyImage />}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-wrap w-1/2">
                        <div className="md:p-2 p-1 w-full">
                            <Link to="/services/business-strategy">
                                <ServiceBlock
                                    title="Business Strategy & Design"
                                    Image={(): React.ReactElement => <BPOImage />}
                                />
                            </Link>
                        </div>

                        <div className="md:p-2 p-1 w-1/2">
                            <Link to="/about-us/innovation">
                                <ServiceBlock title="Innovation" Image={(): React.ReactElement => <NetworkImage />} />
                            </Link>
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <Link to="/services/cloud/cloud-application-management">
                                <ServiceBlock
                                    title="Application Services"
                                    Image={(): React.ReactElement => <DAIImage />}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
