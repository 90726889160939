import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Link } from 'gatsby';

import Layout from '../../components/HomeLayout';
import SEO from '../../components/seo';

import Hero from '../../components/HomeHero';
import ContactUs from '../../components/ContactUs';

import BPOImage from '../../components/Images/services/BusinessProcessAndOperations';

import ContanctOptions from './components/options';

import { ADDRESS, EMAIL, NAME, PHONE } from '../../constants';

const contiguousColor = '#060927';

const IndexPage = (): React.ReactElement => {
    return (
        <>
            <SEO title="Home" />
            <Layout>
                <section>
                    <div className="relative">
                        <Hero className="flex relative" bgColor="#1360ef" waveColor={'transparent'}>
                            <div className="container mx-auto text-white text-center">
                                <h4 className="text-xl mb-4 font-light"></h4>
                                <h1 className="text-6xl font-semibold leading-tight mb-8 tracking-tight">
                                    Contact {NAME} in Australia
                                </h1>

                                <div className="text-2xl font-light pb-5 tracking-normal tracking-tight">
                                    <p>
                                        Thank you for your interest in contacting us. Whatever your enquiry, we will
                                        help direct you to the right contact information.
                                    </p>
                                </div>
                            </div>
                        </Hero>
                    </div>
                </section>
                {/* 
            <section className="relative">
                <div
                    className="z-10"
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        top: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
                            style={{ stroke: null, fill: '#1360ef' }}
                        ></path>
                    </svg>
                </div>
                <div className="absolute inset-0 bg-gray-300 z-0">
                    <iframe
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        title="map"
                        scrolling="no"
                        src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=450%20Smith%20St%20Collingwood%20VIC%203066&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                        style={{ filter: 'grayscale(1) contrast(1.2) opacity(0.4)' }}
                    ></iframe>
                </div>
                <div className="container px-5 py-24 mx-auto flex">
                    <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10">
                        <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Feedback</h2>
                        <p className="leading-relaxed mb-5 text-gray-600">Phone: +12 3228 - 321 - 883</p>

                        <BPOImage />

                        <p className="text-xs text-gray-500 mt-3">Schedule a consultation today</p>
                    </div>
                </div>
            </section> */}

                <section className="text-gray-700 body-font relative">
                    <ContanctOptions />

                    <div className="container mx-auto px-4 pt-5">
                        <div className="flex flex-col">
                            <div className="h-1 bg-gray-200 rounded overflow-hidden">
                                <div className="w-24 h-full bg-indigo-500"></div>
                            </div>
                        </div>
                    </div>
                    <section className="text-gray-700 body-font mt-5">
                        <div className="container px-5 mx-auto">
                            <div className="flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                                <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">
                                    SPEAK WITH AN EXPERT: Our experts are available for a free 30 minute consult
                                </h1>
                                <a
                                    className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0"
                                    href="/services/cloud/modernization"
                                >
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </section>

                    <div className="container px-5 py-24 mx-auto flex sm:flex-no-wrap flex-wrap">
                        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                            <iframe
                                width="100%"
                                height="100%"
                                className="absolute inset-0"
                                frameBorder="0"
                                title="map"
                                marginHeight="0"
                                marginWidth="0"
                                scrolling="no"
                                src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=melbourne&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                                style={{ filter: 'grayscale(1) contrast(1.2) opacity(0.4)' }}
                            ></iframe>
                            <div className="bg-white relative flex flex-wrap py-6">
                                <div className="lg:w-1/2 px-6">
                                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm">
                                        ADDRESS
                                    </h2>
                                    <p className="leading-relaxed">{ADDRESS}</p>
                                </div>
                                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm">
                                        EMAIL
                                    </h2>
                                    <a className="text-indigo-500 leading-relaxed">{EMAIL}</a>
                                    <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mt-4">
                                        PHONE
                                    </h2>
                                    <p className="leading-relaxed">{PHONE}</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 p-5 rounded-md">
                            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Feedback</h2>
                            <p className="leading-relaxed mb-5 text-gray-600">
                                We welcome all suggestions and feedback.
                            </p>
                            <input
                                className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4"
                                placeholder="Name"
                                type="text"
                            />
                            <input
                                className="bg-white rounded border border-gray-400 focus:outline-none focus:border-indigo-500 text-base px-4 py-2 mb-4"
                                placeholder="Email"
                                type="email"
                            />
                            <textarea
                                className="bg-white rounded border border-gray-400 focus:outline-none h-32 focus:border-indigo-500 text-base px-4 py-2 mb-4 resize-none"
                                placeholder="Message"
                            ></textarea>
                            <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                Submit Feedback
                            </button>
                            <p className="text-xs text-gray-500 mt-3">We will never share your details</p>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default IndexPage;
