// Text Ready OK
import React from 'react';
import { Link } from 'gatsby';

import TechImage from '../../../../../components/Images/services/Technology';
import ResiliencyImage from '../../../../../components/Images/services/Resiliency';
import CloudImage from '../../../../../components/Images/services/Cloud';

const SecondPage = () => (
    <>
        <section className="body-font">
            <div className="container px-5 py-8 mx-auto">
                <div className="flex flex-col text-center w-full mb-8">
                    <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
                        MODERNIZATION
                    </h2>
                    <h1 className="sm:text-5xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                        Key Capabilities
                    </h1>
                </div>

                <div className="flex flex-wrap -m-4">
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <TechImage className="h-40 rounded w-full object-cover object-center mb-6" alt="content" />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Cloud Strategy for Enterprise
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                Converge business and IT specifications to cloud platforms advisory recommendations and
                                an operating roadmap through functional and business-case focussed design.
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <ResiliencyImage
                                className="h-40 rounded w-full object-cover object-center mb-6"
                                alt="content"
                            />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Cloud Architecture Design
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800" textReady={60}>
                                Delivers a comprehensive technical architecture and implementation roadmap encompassing
                                infrastructure, virtualization, resiliency, workstations, management tooling, services
                                and providers.
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <CloudImage className="h-40 rounded w-full object-cover object-center mb-6" alt="content" />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Strategy Compliance & Cloud Security
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800" textReady={60}>
                                No-trust end-to-end security evaluation, risk assessment and compliance adherence for
                                cloud technologies. DevOps and roadmap implementation and management.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default SecondPage;
