// Text Ready OK
import React from 'react';

const SecondPage = () => (
    <>
        <h4 className="text-xl mb-4 font-light">
            Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity
        </h4>
        <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight">
            Innovate, and the World Will Thrive
        </h1>
        <div className="text-2xl font-light pb-5 tracking-normal tracking-tight">
            <p>
                Human Ingenuity. Industry Expertise. Cutting-edge tech. We bring all the elements together to improve
                business, society and the world we live in
            </p>
        </div>
    </>
);

export default SecondPage;
