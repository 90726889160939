import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

type Props = {
    className: string;
    bgColor: string;
    waveColor: string;
};

const BI = styled(BackgroundImage)`
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center -90%;

    padding-top: 10rem;
    padding-bottom: 15rem;
`;

const BackgroundSection = ({
    className,
    bgColor,
    waveColor,
    children,
}: PropsWithChildren<Props>): React.ReactElement => {
    const data = useStaticQuery(graphql`
        query {
            bg: file(relativePath: { eq: "bg.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <BI Tag="section" className={className} fluid={data.bg.childImageSharp.fluid} backgroundColor={bgColor}>
            {children}

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: waveColor }}
                    ></path>
                </svg>
            </div>
        </BI>
    );
};

export default BackgroundSection;
