import React from 'react';

const NotFoundPage = (): React.ReactElement => (
    <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full">
            <h1 className="sm:text-3xl text-4xl font-bold title-font mb-4 ">General Enquiries</h1>
        </div>
        <div className="flex flex-wrap">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 ">
                <h2 className="text-xl sm:text-xl font-semibold title-font mb-2">Solution for my business</h2>
                <p className="leading-relaxed text-lg mb-4">
                    We can assist you at all stages in your journey to digital modernization. Speak to our experienced
                    strategy and planning experts to see how we can help your business today.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 ">
                <h2 className="text-xl sm:text-xl font-semibold title-font mb-2">Careers</h2>
                <p className="leading-relaxed text-lg mb-4">
                    Tell us more about yourself and we'll keep you up-to-date regarding upcoming events and career
                    opportunities that match your interests. Contact our HR by sending us an email.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 ">
                <h2 className="text-xl sm:text-xl font-semibold title-font mb-2">Partners</h2>
                <p className="leading-relaxed text-lg mb-4">
                    For new partnership enquiries and existing partner relations contact our liasons by sending us a an
                    email.
                </p>
            </div>
        </div>
    </div>
);

export default NotFoundPage;
