// textReady OK
import React from 'react';

// import { graphql, useStaticQuery } from 'gatsby';
// import BackgroundImage from 'gatsby-background-image';
// import Link from 'gatsby-link';

import { Industries, El } from './Industries/list';

import CollabImage from './Images/contextual/Industries';

type Props = {
    className: string;
    bgColor: string;
    waveColor: string;
};

export default function Comp(): React.ReactElement {
    return (
        <section className="text-gray-700 body-font">
            <div className="container px-5 py-5 mx-auto">
                <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2">Industries</h1>
                    <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                </div>

                <div className="flex py-5 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" textReady={80}>
                            Our industry-wide innovation helps global enterprise adapt to rapidly evolving business,
                            customer, and technological paradigm shifts
                        </h1>
                        <p className="mb-8 leading-relaxed text-lg" textReady={80}>
                            We help create digital advancements and focus on customer centric business models is
                            creating new opportunities for enterprises.
                        </p>
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <CollabImage />
                    </div>
                </div>

                <div className="flex flex-wrap -m-4">
                    {Industries.map((industry) => (
                        <El key={industry.title} {...industry} />
                    ))}
                </div>
            </div>
        </section>
    );
}
