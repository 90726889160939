/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { PropsWithChildren } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styled from 'styled-components';
import tw from 'twin.macro';

import Header from './header';
import Footer from './footer';

const BodyContainer = styled.div`
    ${tw`leading-normal tracking-normal font-lato antialiased`};
`;

const Layout = ({ children }: PropsWithChildren<{}>): React.ReactElement => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <BodyContainer className="realtive">
                <div className="relative" style={{ backgroundColor: '#1360ef' }}>
                    <div className="z-50 w-full text-white container mx-auto mb-4">
                        <Header siteTitle={data.site.siteMetadata.title} />
                    </div>
                </div>

                <main>{children}</main>
                <Footer />
            </BodyContainer>
        </>
    );
};

export default Layout;
