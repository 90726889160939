// Text Ready OK
import React from 'react';

// import TechImage from '../../../../components/Images/services/Technology';
// import ResiliencyImage from '../../../../components/Images/services/Resiliency';
// import CloudImage from '../../../../components/Images/services/Cloud';

const SecondPage = () => (
    <>
        <section className="text-white body-font">
            <div className="container px-5 py-8 mx-auto">
                <div className="flex flex-col text-center w-full mb-8">
                    <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1" textReady={80}>
                        INTELLIGENT CLOUD TECHNOLOGY SUPPORT
                    </h2>
                    <h1
                        className="sm:text-5xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold"
                        textReady={80}
                    >
                        How we can help keep your business solutions dynamic and cost-effective
                    </h1>
                </div>

                <div className="flex flex-wrap -m-4">
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 h-full p-6 rounded-lg">
                            <p className="leading-relaxed text-base text-gray-800" textReady={90}>
                                Immediate on-site resources to perform specific data center tasks, including
                                installations, equipment moves, adds, changes, decommissioning, cabling, and media
                                destruction Emergency on-site data center resources and operations, infrastructure
                                relocations and modifications, setups and configurations, enterprise scale networking
                                and cabling, and media recovery and destruction
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 h-full p-6 rounded-lg">
                            <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                Device deployment and desktop virtualization for remote work and secure, reliable access
                                to business data Hybrid physical and virtualized client and server architecture
                                deployments for remote distributed work environments, with resilient "always-on" data
                                availability
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 h-full p-6 rounded-lg">
                            <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                Remote enabled high availability IT experts and engineers to diagnose and resolve
                                hardware and software issues
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 h-full p-6 rounded-lg">
                            <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                Infrastructure maintenance strategy implementations to optimize end ensure business
                                operations, remain cost-effective to improve and maximize ROI
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default SecondPage;
