// Text Ready OK
import React from 'react';
// import { Link } from 'gatsby';

import styled from 'styled-components';
// import tw from 'twin.macro';

import AnalyticsOnDemandIcon from '../../../../assets/icons/analytics-on-demand.svg';
import AIIcon from '../../../../assets/icons/ai.svg';
import AugmentedRealityIcon from '../../../../assets/icons/augmented-reality.svg';
import TechnicalSupportIcon from '../../../../assets/icons/techical-support-appliance.svg';

const c = '#8090b5';

const s = {
    backgroundColor: 'rgba(19, 96, 239, .1)',
};

const BodyContainer = styled.div`
    path {
        fill: #fff !important;
    }
`;

const SecondPage = () => (
    <>
        <BodyContainer className="">
            <div className="flex flex-col text-center w-full mb-4">
                <h2 className="text-lg tracking-widest font-medium title-font mb-1" textReady={60}>
                    SINGLE-ORIGIN TECHNOLOGY SUPPORT
                </h2>
                <h1 textReady={80} className="sm:text-3xl text-4xl font-bold title-font">
                    Support partners driven by innovation
                </h1>
            </div>
            <div className="flex flex-wrap">
                <div className="md:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6  h-full rounded-md" style={s}>
                        <div className="w-full flex justify-center">
                            <div className=" w-16 inline-flex rounded-full   justify-center">
                                <AnalyticsOnDemandIcon fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>

                        <h2 className="text-center text-2xl sm:text-xl font-semibold title-font mb-2">
                            Analytics on demand
                        </h2>
                        <p
                            className="leading-relaxed text-lg mb-4"
                            style={{ color: c }}
                            textReady={'no search results found'}
                        >
                            Get data-driven insights to improve the availability and efficiency of your infrastructure.
                        </p>
                    </div>
                </div>

                <div className="md:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6 h-full  rounded-md" style={s}>
                        <div className="w-full flex justify-center">
                            <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                                <AIIcon fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>
                        <h2 className="text-center text-2xl sm:text-xl font-semibold title-font mb-2">AI</h2>
                        <p
                            className="leading-relaxed text-lg mb-4"
                            style={{ color: c }}
                            textReady={'no search results found'}
                            textReady={20}
                        >
                            Leverage AI driven technology to solve technical issues faster
                        </p>
                    </div>
                </div>

                <div className="md:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6 h-full  rounded-md" style={s}>
                        <div className="w-full flex justify-center">
                            <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                                <AugmentedRealityIcon fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>
                        <h2 className="text-2xl text-center sm:text-xl font-semibold title-font mb-2">
                            Augmented reality
                        </h2>
                        <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                            Industry extpert support to diagnose, trouble-shoot and resolve remote on-site problems
                        </p>
                    </div>
                </div>

                <div className="md:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6 h-full  rounded-md" style={s}>
                        <div className="w-full flex justify-center">
                            <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                                <TechnicalSupportIcon fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>
                        <h2 className="text-2xl text-center sm:text-xl font-semibold title-font mb-2" textReady={80}>
                            Downtime Minimization
                        </h2>
                        <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                            Manage infrastructure uptime, mitigate outages and intelligent maintenance workflows
                        </p>
                    </div>
                </div>
            </div>
        </BodyContainer>
    </>
);

export default SecondPage;
