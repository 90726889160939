// Text Ready OK
import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import TalentIcon from '../../../../assets/icons/talent-organization-services.svg';
import CFOIcon from '../../../../assets/icons/efficiency-and-cost.svg';
import MarketingSalesAndServiceIcon from '../../../../assets/icons/marketing-sales-and-services.svg';
import SupplyChainAndOperations from '../../../../assets/icons/supply-chain-and-operations.svg';
import TechnologyStrategyAndAdvisory from '../../../../assets/icons/technology-strategy-and-advisory.svg';

// type Props = {
//     className: string;
//     bgColor: string;
//     waveColor: string;
// };

const BI = styled('div')`
    background-color: rgba(19, 96, 239, 0.02);
    ${tw`p-6 rounded-lg`}

    p {
        color: #8090b5;
    }
`;

const El = ({ Icon, title, content }: { Icon: string; title: string; content: string }) => {
    return (
        <div className="xl:w-1/3 md:w-1/2 p-4">
            <BI>
                <div className="relative flex items-center justify-center mb-2">
                    <div className="w-10 h-10 inline-flex items-center rounded-full mr-3 justify-center">
                        <Icon fill={'#4a5568'} width="100" height="100" />
                    </div>
                    <h2 className="text-xl inline-flex font-medium title-font">{title}</h2>
                </div>
                <p>{content}</p>
            </BI>
        </div>
    );
};

// textReady={80}
const Industries = [
    {
        Icon: TalentIcon,
        title: `Talent and Organization Services`,
        content: 'Digitally optmize HR with AI assisted hiring decisions, talent acquisition and retention',
    },
    {
        Icon: CFOIcon,
        title: `Enterprise Value`,
        content: 'Increase your financial reach and outcomes across the enterprise.',
    },
    {
        Icon: MarketingSalesAndServiceIcon,
        title: `Marketing, Sales, and Service`,
        content: 'Achieve personalized, responsible and relevant customer experiences.',
    },
    {
        Icon: SupplyChainAndOperations,
        title: `Strategic Sourcing Operations`,
        content:
            'Leverage AI solutions to optimize supply chain and operations in competitive industrusies and existing markets.',
    },
    {
        Icon: TechnologyStrategyAndAdvisory,
        title: `Technology Strategy`,
        content: 'Drive higher-level outcomes with innovation-led strategy and cutting-edge technologies.',
    },
];

const SecondPage = () => (
    <>
        <h1 className="text-3xl font-medium title-font mb-8">Enterprise Function Consulting</h1>

        <div className="flex flex-wrap -m-4">
            {Industries.map((industry) => (
                <El key={industry.title} {...industry} />
            ))}
        </div>
    </>
);

export default SecondPage;
