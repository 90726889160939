// Text Ready OK
import React from 'react';
import { Link } from 'gatsby';

import TechImage from '../../../../../components/Images/services/Technology';
import ResiliencyImage from '../../../../../components/Images/services/Resiliency';
import CloudImage from '../../../../../components/Images/services/Cloud';

const SecondPage = () => (
    <>
        <section className=" body-font">
            <div className="container px-5 py-8 mx-auto">
                <div className="flex flex-col text-center w-full mb-8">
                    <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
                        BUSINESS PROCESS
                    </h2>
                    <h1 className="sm:text-5xl text-6xl font-medium title-font mb-4 font-semibold">Key Capabilities</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl ">IBM can help you:</p>
                </div>

                <div className="flex flex-wrap -m-4">
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <Link to="/services/cloud/enterprise-cloud-strategy">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <TechImage
                                    className="h-40 rounded w-full object-cover object-center mb-6"
                                    alt="content"
                                />
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    Procurement and Strategic Sourcing
                                </h2>
                                <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                    Procurement and sourcing are identified as prime divisions for digital
                                    transformation, with the advent of AI predictive strategies and autonomous logisitcs
                                    your team will be more empowered to join the future of procurement. Our advisory
                                    specialists can also assist teams in integrating procument analytics as a service.
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <Link to="/services/cloud/modernization">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <ResiliencyImage
                                    className="h-40 rounded w-full object-cover object-center mb-6"
                                    alt="content"
                                />
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    Finance Transformation Services
                                </h2>
                                <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                    We have re-envisioned and transformed Business Process Outsourcing and PaaS for
                                    financial institutions and firms all over the world. Discover how AI driven insights
                                    and data-analytics can transform your finance teams.
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <Link to="/services/cloud/cloud-native">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <CloudImage
                                    className="h-40 rounded w-full object-cover object-center mb-6"
                                    alt="content"
                                />
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    Talent and Transformation Services
                                </h2>
                                <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                    The emergence of new digital trends lead to changes in management in HR
                                    transformation. Learn how our experts can merge talent retention and acquisition,
                                    development and strategy to modernize and transform your teams and operations with
                                    intelligent workflows.
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default SecondPage;
