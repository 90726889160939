// Text Ready OK
import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Link from 'gatsby-link';

import AppliedIntelligenceIcon from '../../../../assets/icons/applied-intelligence.svg';
import EnterpriseFunctionConsultingIcon from '../../../../assets/icons/enterprise-function-consulting.svg';
import Industry40Icon from '../../../../assets/icons/industry-4_0.svg';
import DesignIcon from '../../../../assets/icons/design.svg';
import InnovationIcon from '../../../../assets/icons/innovation.svg';
import IndustryConsultingIcon from '../../../../assets/icons/industry-consulting.svg';
import StrategyIcon from '../../../../assets/icons/strategy.svg';

type Props = {
    className: string;
    bgColor: string;
    waveColor: string;
};

const BI = styled('div')`
    background-color: rgba(19, 96, 239, 0.02);
    ${tw`p-6 rounded-lg`}

    p {
        color: #8090b5;
    }
`;

const El = ({ Icon, title, content, slug }: { Icon: string; title: string; content: string }) => {
    return (
        <div className="xl:w-1/3 md:w-1/2 p-4">
            <BI>
                <div className="relative flex flex-column items-center justify-center mb-2">
                    <div className="w-10 h-10 inline-flex items-center rounded-full   justify-center">
                        <Icon fill={'#4a5568'} width="100" height="100" />
                    </div>
                    <h2 className="ml-4 text-xl inline-flex font-medium title-font">{title}</h2>
                </div>
                <p>{content}</p>
            </BI>
        </div>
    );
};

const Industries = [
    {
        Icon: EnterpriseFunctionConsultingIcon,
        title: `Enterprise Methodologies`,
        // content: 'We help clients re-imagine their business functions to navigate change, and accelerate growth.',
        content: 'We help clients embrace change, re-envision their business methodologies and boost growth.',
    },
    {
        Icon: Industry40Icon,
        title: `Industry Moderniztion`,
        // content: 'We create the next wave of industry innovation with software, platforms, data and AI.',
        content:
            'We help cients navigate emerging trends of industry change with cloud, automation, platforms, data and AI.',
    },
    {
        Icon: AppliedIntelligenceIcon,
        title: `Applied Intelligence`,
        // content: 'We use Applied Intelligence, combining AI with data, analytics and automation, to transform businesses.',
        content: 'We combine AI with analytics and automation to transform businesses and accelerate growth.',
    },
    {
        Icon: DesignIcon,
        title: `Design`,
        // content: 'We bring breakthrough connected products and services to life, redesigning how things operate.',
        content: 'We bring game-changing ideas to life, re-imagining how businesses function.',
    },
    {
        Icon: InnovationIcon,
        title: `Innovation`,
        // content: 'We apply the unexpected to deliver disruptive ways of winning, taking our clients from research to results.',
        content:
            'We embrace disruption to build revolutionary products and services, that take our clients to the highest levels of industry leadership.',
    },
    {
        Icon: IndustryConsultingIcon,
        title: `Industry Consulting`,
        // content: 'We help clients transform across their own organization and cross-industry ecosystems.',
        content: 'We help businesses transform within their existing ecosystems and across industry verticals.',
    },
    // {
    //     Icon: StrategyIcon,
    //     title: `Strategy`,
    //     // content: 'We partner with boards and CEOs to enable competitive agility and address the most strategic C-suite issues.',
    //     content:
    //         'We partner with boards and CEOs to enable competitive agility and address the most strategic C-suite issues.',
    // },
];

export default function Comp(): React.ReactElement {
    return (
        <section className="text-gray-700 body-font">
            <div className="container mx-auto">
                <h1 className="text-3xl font-medium title-font mb-8">Networked Capabilities</h1>

                <div className="flex flex-wrap -m-4">
                    {Industries.map((industry) => (
                        <El key={industry.title} {...industry} />
                    ))}
                </div>
            </div>
        </section>
    );
}
