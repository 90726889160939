import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

export default function MyFils({ data }: { data: any }): React.ReactElement {
    console.log(data);
    return (
        <Layout>
            <div>All Files</div>
            <div>
                {data.allFile.edges.map(
                    (
                        {
                            node,
                        }: {
                            node: {
                                relativePath: string;
                                prettySize: string;
                                extension: string;
                                birthTime: string;
                            };
                        },
                        index,
                    ): any => {
                        return (
                            <div key={index}>
                                <div>{node.relativePath}</div>
                                <div>{node.prettySize}</div>
                                <div>{node.extension}</div>
                                <div>{node.birthTime}</div>
                            </div>
                        );
                    },
                )}
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        allFile {
            edges {
                node {
                    relativePath
                    prettySize
                    extension
                    birthTime(fromNow: true)
                }
            }
        }
    }
`;
