// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import SEO from '../../../../components/seo';

import Layout from '../../../../components/layout';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />
        <h1>TO BE IMPLEMENTED</h1>
    </Layout>
);

export default SecondPage;
