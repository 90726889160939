// Text Ready OK
import React from 'react';

import CloudServiceImage from '../../../../components/Images/infographics/data-processing';

const SecondPage = () => (
    <>
        <h4 className="text-xl mb-4 font-light">
            Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity
        </h4>
        <h1 className="text-6xl font-semibold leading-tight mb-3 tracking-tight">Strategy & Consulting</h1>
        <div className="text-2xl font-light pb-5 tracking-normal tracking-tight">
            <p>
                We partner with and enable our clients at every step along the way of their journey to modernization
                rapidly and at scale.
            </p>
        </div>
        <div className="lg:w-2/3 mx-auto">
            <CloudServiceImage />
        </div>
    </>
);

export default SecondPage;
