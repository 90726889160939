// Gatsby supports TypeScript natively!
import React from 'react';

function Tick(content: string): React.ReactElement {
    return (
        <div className="bg-gray-200 rounded flex p-4 items-center mb-3">
            <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4"
                viewBox="0 0 24 24"
            >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
            </svg>
            <span className="title-font font-medium">{content}</span>
        </div>
    );
}

const SecondPage = ({ options }: { options: string[] }): React.ReactElement => (
    <>
        <div className="flex flex-wrap lg:w-4/5 sm:mb-2">
            <div className="p-2 w-full">
                {options.map(
                    (option: string): React.ReactElement => {
                        return Tick(option);
                    },
                )}
            </div>
        </div>
    </>
);

export default SecondPage;
