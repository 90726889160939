import React from 'react';

// import Img from 'gatsby-image';

import ClientImageAnz from '../Images/clients/ClientImageAnz';
import ClientImageBP from '../Images/clients/ClientImageBP';
import ClientImageCBA from '../Images/clients/ClientImageCBA';
import ClientImageCityOfMelbourne from '../Images/clients/ClientImageCityOfMelbourne';
import ClientImageEnergyAustralia from '../Images/clients/ClientImageEnergyAustralia';
import ClientImageSAPlanning from '../Images/clients/ClientImageSAPlanning';
import ClientNAB from '../Images/clients/ClientImageNAB';
import ClientNBN from '../Images/clients/ClientImageNBN';
import ClientNestle from '../Images/clients/ClientImageNestle';
import ClientQLDHousing from '../Images/clients/ClientImageQLDHousing';
import ClientQLDTransport from '../Images/clients/ClientImageQLDTransport';
import ClientSAPower from '../Images/clients/ClientImageSAPower';
import ClientSuncorp from '../Images/clients/ClientImageSuncorp';
import ClientSuperfriend from '../Images/clients/ClientImageSuperfriend';
import ClientSydneyMetro from '../Images/clients/ClientImageSydneyMetro';
import ClientTelstra from '../Images/clients/ClientImageTelstra';
import ClientUCQ from '../Images/clients/ClientImageUCQ';
import ClientVirginAU from '../Images/clients/ClientImageVirginAU';
import ClientWestpac from '../Images/clients/ClientImageWestpac';

export const El = ({ Image }) => {
    return <Image width="300" height="300" />;
};

export const Clients = [
    {
        Image: ClientImageAnz,
        name: 'ANZ',
    },
    {
        Image: ClientImageBP,
        name: 'BP',
    },
    {
        Image: ClientImageCBA,
        name: 'CBA',
    },
    {
        Image: ClientImageCityOfMelbourne,
        name: 'City of Melbourne',
    },
    {
        Image: ClientImageEnergyAustralia,
        name: 'Energy Australia',
    },
    {
        Image: ClientImageSAPlanning,
        name: 'Government of South Australia Deptartment of Planning, Transport and Infrustrutcture',
    },
    {
        Image: ClientNAB,
        name: 'NAB',
    },
    {
        Image: ClientNBN,
        name: 'NBN',
    },
    {
        Image: ClientNestle,
        name: 'Nestle',
    },
    {
        Image: ClientQLDHousing,
        name: 'Government of Queensland Department of Housing and Public Works',
    },

    {
        Image: ClientSAPower,
        name: 'SA Power Networks',
    },
    {
        Image: ClientSuncorp,
        name: 'Suncorp',
    },
    {
        Image: ClientSuperfriend,
        name: 'Superfriend',
    },
    {
        Image: ClientSydneyMetro,
        name: 'NSW Sydney Metro',
    },
    {
        Image: ClientTelstra,
        name: 'Telstra',
    },
    // {
    //     Image: ClientUCQ,
    //     name: 'Uniting Care Queensland',
    // },
    {
        Image: ClientVirginAU,
        name: 'Virgin Australia',
    },
    {
        Image: ClientWestpac,
        name: 'Westpac',
    },
    {
        Image: ClientQLDTransport,
        name: 'Government of Queensland Department of Transport and Main Roads',
    },
];
