// Text Ready OK
import React from 'react';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import Intro from './components/intro';
import SupportViaInnovation from './components/SupportViaInnovation';
import Capabilities from './components/capabilities';
import LongTermBenefits from './components/LongTermBenefits';
import SpeakToAnExpert from '../../../components/page/SpeakToAnExpert';

import TickedList from '../../../components/page/Lists/Ticked';

import TechnologyServicesImage from '../../../components/Images/infographics/technology-services';
import { NAME } from '../../../constants';

const contiguousColor = '#ececec';
const darkBlue = '#060927';

const NotFoundPage = (): React.ReactElement => (
    <Layout>
        <SEO title="404: Not found" />

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    <Intro />
                </div>

                <div className="flex py-5 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1
                            className="title-font sm:text-2xl text-3xl mb-4 font-medium text-gray-900"
                            textReady={`no search results found`}
                        >
                            Technology support for hybrid cloud environments that are
                            <strong>global, open and constantly changing</strong>
                        </h1>
                        <p className="mb-8 leading-relaxed">Technology Support Services {NAME} can offer you:</p>

                        <TickedList
                            textReady={`no search results found`}
                            options={[
                                'Preventative maintenance and support for mission-critical systems',
                                'Remote application support for third-party proprietary software, including day-to-day application management, as needed',
                                'Support configured to your needs, from base to premium services with technical account managers who act as an extension of your IT team',
                                'On-site or remote support, especially when your IT teams are constrained, including augmented reality for faster problem resolution.',
                            ]}
                        />
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <TechnologyServicesImage />
                    </div>
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: darkBlue }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: darkBlue,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 mx-auto text-white">
                <SupportViaInnovation />
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-10 mx-auto">
                <Capabilities />
            </div>

            <div className="container px-5 py-10 mx-auto">
                <LongTermBenefits />
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                textReady={70}
                title="We can help with application modernization"
                content="We are your partner on all steps of your modernization journey. Our strategy and application experts can help whether you need to
                            modernize your business driven applicaitons or build cloud-native applications."
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
