// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import Intro from './components/intro';

import ResearchImage from '../../../components/Images/innovation/research';
import LabsImage from '../../../components/Images/innovation/labs';
import StudiosImage from '../../../components/Images/innovation/studios';
import VenturesImage from '../../../components/Images/innovation/ventures';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4 mb-8">
                    <Intro />
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <div className="flex py-5 md:flex-row flex-col items-center mb-12">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Research</h1>

                        <p className="mb-8 leading-relaxed text-xl" textReady={70}>
                            Our forward thinking research teams discover emerging trends and revolutionary paradigm
                            shifts in business, technology and society.
                        </p>

                        <p className="mb-8 leading-relaxed text-xl" textReady={80}>
                            Our diverse, creative and passionate researchers partnet with established industry leaders
                            to identify and create cutting-edge ground-breaking enterprise technologies
                        </p>
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <ResearchImage />
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <div className="flex py-5 md:flex-row flex-col items-center mb-12">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                            Growth Investment
                        </h1>
                        <p className="mb-8 leading-relaxed text-xl" textReady={80}>
                            Our incubator leadership invests in seed-stage start-ups and ambitious teams that develop
                            game-changing solutions for our clients and the society they serve
                        </p>
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <VenturesImage />
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <div className="flex py-5 md:flex-row flex-col items-center mb-12">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Labs</h1>
                        <p className="mb-8 leading-relaxed text-xl">
                            Our labs help clients explore and workshop new ideas and concepts that generate higher-level
                            outcomes and industry growth.
                        </p>
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <LabsImage />
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <div className="flex py-5 md:flex-row flex-col items-center mb-12">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                            Digital Studios
                        </h1>
                        <p className="mb-8 leading-relaxed text-xl">
                            Our studios leverage a rich pool of tech and talent and work across a wide array of
                            specializations, tools and verticals - from mobile to cloud - that bring the vision of our
                            clients from concept to reality.
                        </p>
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <StudiosImage />
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
