import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ siteTitle }: { siteTitle: string }): React.ReactElement => (
    <header className=" ">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <Link to="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                <span className="ml-3 text-2xl text-white font-medium">{siteTitle}</span>
            </Link>
            <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
                <Link to="/services" className="mr-5 hover:text-gray-900">
                    Services
                </Link>
                <Link to="/industries" className="mr-5 hover:text-gray-900">
                    Industries
                </Link>
                <Link to="/about-us" className="mr-5 hover:text-gray-900">
                    About Us
                </Link>
            </nav>
            <Link
                to="/contact-us"
                className="inline-flex text-gray-700 items-center bg-gray-200 border-0 py-1 px-3 focus:outline-none hover:bg-gray-300 rounded text-base mt-4 md:mt-0"
            >
                Contact Us
                <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-1"
                    viewBox="0 0 24 24"
                >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </Link>
        </div>
    </header>
);

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
