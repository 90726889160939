// Text Ready OK
import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';
import tw from 'twin.macro';

import DeployFasterIcon from '../../../../../assets/icons/faster-migration.svg';
import ChangeIcon from '../../../../../assets/icons/modernize.svg';
import InnovationIcon from '../../../../../assets/icons/innovation.svg';

const BodyContainer = styled.div`
    path {
        fill: #fff !important;
    }
`;

const c = '#8090b5';

const SecondPage = () => (
    <>
        <BodyContainer className="">
            <div className="flex flex-col text-center w-full mb-4">
                <h2 className="text-lg tracking-widest font-medium title-font mb-1">HOW THE CLOUD HELPS</h2>
                <h1 className="sm:text-3xl text-4xl font-bold title-font" textReady={80}>
                    We help business build cloud native applications
                </h1>
            </div>
            <div className="flex flex-wrap">
                <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-gray-500">
                    <div className="w-full flex justify-center mb-3">
                        <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                            <DeployFasterIcon fill={'#fff'} width="100" height="100" />
                        </div>
                    </div>

                    <h2 className="text-2xl text-center sm:text-xl font-semibold title-font mb-2">Deploy faster</h2>
                    <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                        Deliver a better market experience, deploy on anywhere and be ready to market twice as fast.
                    </p>
                </div>
                <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 ">
                    <div className="w-full flex justify-center mb-3">
                        <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                            <ChangeIcon fill={'#fff'} width="100" height="100" />
                        </div>
                    </div>
                    <h2 className="text-center text-2xl sm:text-xl font-semibold title-font mb-2">
                        Integrage new capabilities
                    </h2>
                    <p className="leading-relaxed text-lg mb-4" style={{ color: c }}>
                        Reinvent your workplace culture and introduce new work routines and methods via thorough
                        workplace efficient assessments.
                    </p>
                </div>
                <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 ">
                    <div className="w-full flex justify-center mb-3">
                        <div className="align-center w-16 inline-flex items-center rounded-full   justify-center">
                            <InnovationIcon fill={'#fff'} width="100" height="100" />
                        </div>
                    </div>
                    <h2 className="text-2xl sm:text-xl font-semibold title-font mb-2">
                        Innovate with confidence, mitigate cost and risk
                    </h2>
                    <p className="leading-relaxed text-lg mb-4" style={{ color: c }}>
                        Enhance the implementation process utilizing our valued experience, team and strategies keeping
                        you cost-effective by reducing spend by up to 25%.
                    </p>
                </div>
            </div>
        </BodyContainer>
    </>
);

export default SecondPage;
