// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

const SecondPage = () => (
    <div className="container flex flex-wrap px-5 py-4 mx-auto items-center">
        <div className="flex flex-col text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">HOW TO START</h2>
            <h1 className="sm:text-3xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                Modernize legacy apps or build from scratch?
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl ">
                At all stages of your journey to modernization we can leverage existing technology and infrastructure
                investments to help you transform rapidly at scale. Our throrough modernization assesments can help
                asses whether building from scratch or enhancing existing applications is the right plan of action for
                your business.
            </p>
        </div>

        <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-300">
            <p className="leading-relaxed text-lg">
                For established business with existing solutions and infrastructure building from scratch{' '}
                <strong>is not necessary</strong>. Transforming and enhancing legacy applications allows your business
                to leverage your existing IT investment while incorporating all the advantages from the flexibility and
                scalability of the cloud.
            </p>
        </div>
        <div className="flex flex-col md:w-1/2 md:pl-12">
            <p className="leading-relaxed text-lg">
                For some businesses, however, <strong>starting from scratch</strong> is easier than enhancing existing
                applications. Building new cloud enabled applications helps your <strong>create the innovation</strong>
                that your audience requires without being limited to previous iterations or decisions.
            </p>
        </div>

        <div className="flex flex-col md:w-1/2 md:pl-12">
            <p className="leading-relaxed text-lg">
                Our modernization assesment can help your decide where you should start.
            </p>
        </div>
    </div>
);

export default SecondPage;
