// Text Ready OK
import React from 'react';
import { Link } from 'gatsby';

import ExtremeAutomationImage from '../../../../../components/Images/services/ExtremeAutomation';
import MultiCloudImage from '../../../../../components/Images/services/CloudApplicationManagement';
import HybridCloudImage from '../../../../../components/Images/services/HybridCloud';
import CloudPortfolioOptimizationImage from '../../../../../components/Images/services/CloudPortfolioOptimization';

const SecondPage = () => (
    <>
        <section className="text-white body-font">
            <div className="container px-5 py-8 mx-auto">
                <div className="flex flex-col text-center w-full mb-8">
                    <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
                        MODERNIZATION
                    </h2>
                    <h1 className="sm:text-5xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                        Key Capabilities
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl ">
                        Our capabilities can help your receive full benefits of the cloud
                    </p>
                </div>

                <div className="flex flex-wrap -m-4">
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <ExtremeAutomationImage
                                className="h-40 rounded w-full object-cover object-center mb-6"
                                alt="content"
                            />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                High automation for minimal and self-enabled maintenance
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800">
                                Self repairing solutions, AI powered operations for resilient applications.
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <MultiCloudImage
                                className="h-40 rounded w-full object-cover object-center mb-6"
                                alt="content"
                            />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Service interoperability via cloud integrations
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800">
                                Provides increase service availability and simplifies distributed service management
                                over multi cloud distributions. Reduces technical debt of service infrastructure
                                implementations, management and maintenance.
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <HybridCloudImage
                                className="h-40 rounded w-full object-cover object-center mb-6"
                                alt="content"
                            />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Full-stack cloud deployments
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800">
                                Enables optimized and enhanced value to market, increased speed of innvation over all
                                operational segments and reduces risk of legacy solutions.
                            </p>
                        </div>
                    </div>
                    <div className="xl:w-1/4 md:w-1/2 p-4">
                        <div className="bg-gray-100 p-6 rounded-lg">
                            <CloudPortfolioOptimizationImage
                                className="h-40 rounded w-full object-cover object-center mb-6"
                                alt="content"
                            />
                            <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Iterative cloud modernization for process optimization
                            </h2>
                            <p className="leading-relaxed text-base text-gray-800">
                                Increases the cloud nativity over time with co-existence based deployments and provides
                                a substantial risk reduction.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default SecondPage;
