// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';

import Intro from '../../../../components/page/Intro/Intro';

import SpeakToAnExpert from '../../../../components/page/SpeakToAnExpert';

import Capabilities from './components/capabilities';
import DefineYourStrategy from './components/DeffineYourStrategy';

import CloudServiceImage from '../../../../components/Images/infographics/services-cloud';
import EnterpriseCloudImage from '../../../../components/Images/infographics/enterprise-cloud';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section className="text-gray-700 body-font">
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4 mb-8">
                    <Intro
                        title="Enterprise Cloud Strategy"
                        subtitle="A hybrid multicloud architecture is the new reality. From inception to deployment to management, we can make the right cloud strategy that will serve as a base on which to deliver the innovation your users demand."
                        Image={() => <EnterpriseCloudImage />}
                    />
                </div>
            </div>
        </section>

        <section>
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <DefineYourStrategy />
            </div>
        </section>

        <section className="mb-20">
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="container mx-auto flex mb-3 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <CloudServiceImage />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-lg mb-4" textReady={80}>
                            Our experience in cloud strategy for business aligns traditional business critical
                            operational solutions and platforms with the new technologies such as hybrid cloud-native
                            architectures and AI powered solutions.
                        </p>

                        <p className="lg:w-2/3 mx-auto leading-relaxed text-lg mb-4" textReady={80}>
                            Our cloud strategy advisory experts can help you design and develop a secure, robust, open
                            solutions that delivers higher-level returns and value to your business.
                        </p>

                        <p className="lg:w-2/3 mx-auto leading-relaxed text-lg " textReady={80}>
                            Our experienced cloud specialists adopt modern cloud frameworks, operational analysis tools
                            and methodologies to guarantee your cloud transformation covers all your business'
                            requirements, stragegies and growth ambitions.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section
            style={{
                backgroundColor: contiguousColor,
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <Capabilities />
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
                >
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                title="We can help with your entrprise cloud strategy"
                content="We're with you every step along the way of your modernization journey. Whether you need to modernize your existing business solutions or build new hybrid cloud applications, our dedicated experts can help."
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
