// Text Ready OK
import React from 'react';
import {
    PageProps,
    // , Link
} from 'gatsby';

import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';

import Intro from '../../../../components/page/Intro/Intro';
// import ContentBlock from '../../../../components/page/Content/block';
import SpeakToAnExpert from '../../../../components/page/SpeakToAnExpert';
// import TickedList from '../../../../components/page/Lists/Ticked';

import Capabilities from './components/capabilities';

// import CloudServiceImage from '../../../images/services-cloud';
import MultiCloudImage from '../../../../components/Images/infographics/services-multicloud';
import CollabImage from '../../../../components/Images/contextual/Collab';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            style={{
                backgroundColor: 'fff',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="py-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    {/**
                     * Text Ready OK
                     */}
                    <Intro
                        title="Outsource Business Operations for continuous and resilient businsses"
                        subtitle="Learn how our business process outsourcing specialists can transform your organization with intelligent workflows to ensure business continuity."
                        Image={() => <MultiCloudImage />}
                    />
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: contiguousColor,
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    <CollabImage />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900" textReady={80}>
                        What is process outsourcing for intelligent workflows?
                    </h1>

                    <h4 className="text-xl mb-4" textReady={80}>
                        Modern technologies have created new opportunities for growth while introducing new challenges
                        to remain relevant and competitive. Intelligent workflows are leveraging automation, the cloud,
                        artificial intelligence and other game-changing trends to transform traditional processes and
                        legacy business solutions.
                    </h4>

                    <p textReady={80}>
                        In the ever changing digital ecosystem busineses face constant need evolve into more
                        intelligent, fully automated operations that take into consideration audiences, markets,
                        employees, suppliers and business partners.
                    </p>
                </div>
            </div>

            <div className="container px-5 py-4 mx-auto">
                <Capabilities />
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                title="Talk to us about getting started"
                content="Our expertise spans a vast array of capabilities, services and products to help kickstart your modernization journey. "
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
