// textReady OK
import React from 'react';

import Intro from '../../../components/page/Intro/Intro';
// import Image from '../../../services/images/services-multicloud';

const SecondPage = (): React.ReactElement => (
    <>
        <Intro
            tags="Strategy Consulting | Interactive | Operations | Technology"
            title="Services"
            textReady={'85%'}
            subtitle="We help transform businesses to cloud-based cognitive digital enterprises"
            // Image={() => <Image />}
        />
    </>
);

export default SecondPage;
