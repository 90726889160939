// textReady OK
import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import Intro from './components/intro';
// import CollabImage from '../../../components/Images/Collab';
import Image from '../../components/Images/infographics/data-security';

// import { Industries, El } from '../../components/Industries/list';
// import Services from '../services/business-strategy/components/services';
// import EnterpriseFunctionConsulting from '../services/business-strategy/components/EnterpriseFunctionConsulting';

import SpeakToAnExpert from '../../components/page/SpeakToAnExpert';

import ServicesIBM from '../../components/ServicesIBM';
import { NAME, titleSEO } from '../../constants';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => {
    const str = `${NAME} is your application modernization partner`;
    return (
        <Layout>
            <SEO title={`Services - ${NAME} - ${titleSEO}`} />

            <section
                style={{
                    backgroundColor: 'fff',
                    paddingTop: '1rem',
                    paddingBottom: '3rem',
                    position: 'relative',
                }}
            >
                <div className="py-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                    <div className="mx-auto lg:w-3/4">
                        <Intro />
                    </div>
                </div>

                <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <Image />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900">
                            You digital strategy partner
                        </h1>
                        <h4 className="text-xl mb-4" textReady={80}>
                            We deliver continuous outcomes for partners to achieve competitive advantages in rapidly
                            changing technological, business and customer landscapes.
                        </h4>
                        <p className="mb-3" textReady={80}>
                            We integrate innovative next-generation technology to evolve your business into a modern
                            cognitive enterprise. Our cutting-edge research leads the way in business transformation.
                        </p>
                        <p className="mb-3" textReady={70}>
                            Our business and technology experts can meet you at any stage of your journey to application
                            modernization and help you reduce cost and risk, achieve compliance, accelerate speed to
                            market, generate new revenue streams and provision secure and resilient AI and hybrid-cloud
                            integrated infrastructure.
                        </p>
                    </div>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: contiguousColor,
                    paddingTop: '5rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <div className="container px-5 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <ServicesIBM />
                    </div>
                </div>

                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#1360ef' }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: '#1360ef',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <SpeakToAnExpert
                    textReady={80}
                    title={str}
                    content="Our insight-driven stragegies and design experts can guide you through all the stages of application modernization: from migration legacy applications to next-generation technologies, to going cloud-native, to cognitive enterprise."
                />
                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#fff' }}
                        ></path>
                    </svg>
                </div>
            </section>
        </Layout>
    );
};

export default SecondPage;
