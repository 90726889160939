// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';

import Intro from '../../../../components/page/Intro/Intro';
import ContentBlock from '../../../../components/page/Content/block';
import SpeakToAnExpert from '../../../../components/page/SpeakToAnExpert';
import TickedList from '../../../../components/page/Lists/Ticked';

import CloudServiceImage from '../../../../components/Images/infographics/services-cloud';
import MultiCloudImage from '../../../../components/Images/infographics/services-multicloud';

import CollabImage from '../../../../components/Images/contextual/Collab';

import Capabilities from './components/capabilities';
import LetUsManageYourApp from './components/LetUsManageYouApp';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4 mb-8">
                    <Intro
                        title="Management services for cloud native solutions"
                        subtitle="Modern application management — leverage the benefits of cloud native "
                        // Image={() => <CloudServiceImage />}
                        textReady={80}
                    />
                </div>

                <div className="flex py-5 md:flex-row flex-col items-center mb-12">
                    <div className="lg:flex-grow md:w-1/2flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                            How can you reduce technical debt of IT management in a complex cloud ecosystems?
                        </h1>
                        <p className="mb-8 leading-relaxed">Our managed Services can help you:</p>

                        <TickedList
                            options={[
                                'Optimize time to innovation and value return',
                                'Simplify management of IT environments with smarter management',
                                'Manage services across platforms and providers',
                                'Strengthen security, attain compliance and ensure resilience of your IT environment',
                                'Leap ahead by leveraging AI & data',
                            ]}
                        />
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <MultiCloudImage />
                    </div>
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container mx-auto lg:w-3/4 items-center">
                <h2 className="text-xl mb-4" textReady={80}>
                    Businesses are transforming to achieve the benefits of cloud native through hybrid and multicloud
                    deployments, resulting in substantial improvementes in application management methodologies.
                </h2>
            </div>

            <div className="container mx-auto flex mb-3 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                    <CloudServiceImage />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <p className="mb-5">These shifts include:</p>
                    <TickedList
                        options={[
                            'A predicted growth of 67% in spending between 2018 and 2022 driven by the cloud shift across key enterprise IT markets.',
                            'Today, only 1 in 5 applications are cloud enabled, and this ratio is estimated to increase, increasing both hybrid and multicloud adoption',
                            'Yet, about 90% of existing applications will continue be used in 2023, with little-to-no cloud capabilities.',
                        ]}
                    />

                    <p textReady={80}>
                        The current practise of application management is quickly being phased-out. A fundamental
                        evolution is needed to manage applications in order to receive the full benefits of cloud.
                        Organizations that welcome this new shift will innovate and edge out competitors.
                    </p>
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section>
            <Capabilities />
        </section>

        <div className="">
            <section
                style={{
                    backgroundColor: 'fff',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <div className="container px-5 py-4 mx-auto">
                    <LetUsManageYourApp />
                </div>

                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#1360ef' }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: '#1360ef',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <SpeakToAnExpert
                    title="How we can help with application management"
                    content="We're with you every step along the way of your modernization journey. Whether you need to modernize your existing business solutions or build new hybrid cloud applications, our dedicated experts can help."
                />
                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#fff' }}
                        ></path>
                    </svg>
                </div>
            </section>
        </div>
    </Layout>
);

export default SecondPage;
