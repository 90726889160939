import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import { AWSImagePath } from '../../constants';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const S3Image = (imageProps: { file: string }): React.ReactElement => {
    // const data = useStaticQuery(graphql`
    //     query {
    //         placeholderImage: file(relativePath: { eq: "services/services-business.jpg" }) {
    //             childImageSharp {
    //                 fluid(maxWidth: 300) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `);

    // return <Img {...imageProps} fluid={data.placeholderImage.childImageSharp.fluid} />;
    return <img src={`${AWSImagePath(imageProps.file)}`} />;
};

export default S3Image;
