//  Text Ready OK
import React from 'react';
import { Link } from 'gatsby';

import CloudImage from '../../../../../components/Images/services/CloudApplicationManagement';
import ResiliencyImage from '../../../../../components/Images/services/CloudApplicationDevelopment';
import TechImage from '../../../../../components/Images/services/EnterpriseCloud';

const SecondPage = () => (
    <>
        <section className="text-white body-font">
            <div className="container px-5 py-8 mx-auto">
                <div className="flex flex-col text-center w-full mb-8">
                    <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
                        MODERNIZATION
                    </h2>
                    <h1 className="sm:text-5xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                        Key Capabilities
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl ">
                        Our cloud capabilities help you build cloud native. We can help you:
                    </p>
                </div>

                <div className="flex flex-wrap -m-4">
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <Link to="/services/cloud/enterprise-cloud-strategy">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <TechImage
                                    className="h-40 rounded w-full object-cover object-center mb-6"
                                    alt="content"
                                />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                                    Envision
                                </h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    Cloud Strategy for Business and Enterprise
                                </h2>
                                <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                    From inception to development to management, we can design the hybrid cloud strategy
                                    suited to your requirements that can deliver innovation your audience demands.
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <Link to="/services/cloud/modernization">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <ResiliencyImage
                                    className="h-40 rounded w-full object-cover object-center mb-6"
                                    alt="content"
                                />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                                    Transform
                                </h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    Journey to the Cloud
                                </h2>
                                <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                    Transform to digital cloud hybrid applications with proven modernization strategies
                                    and technologies, such as: microservices, containers, virtualization and DevOps
                                    tools.
                                </p>
                            </div>
                        </Link>
                    </div>
                    <div className="xl:w-1/3 md:w-1/2 p-4">
                        <Link to="/services/cloud/cloud-native">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <CloudImage
                                    className="h-40 rounded w-full object-cover object-center mb-6"
                                    alt="content"
                                />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                                    Execute
                                </h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    Become a Cloud-Native Business
                                </h2>
                                <p className="leading-relaxed text-base text-gray-800" textReady={80}>
                                    Go cloud native to speed-up the value of innovation. Keep spend low and maintain
                                    cost-efficiency, reach faster time to market and increase revenue growth with open,
                                    secure, cloud solutions.
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default SecondPage;
