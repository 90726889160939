// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import EfficiencyAndCostIcon from '../../../../../assets/icons/efficiency-and-cost.svg';
import AvailabilityAndResillienceIcon from '../../../../../assets/icons/availability-and-resilience.svg';
import SpeedAndAgilityIcon from '../../../../../assets/icons/speed-and-agility.svg';
import SecurityIcon from '../../../../../assets/icons/security.svg';

const SecondPage = () => (
    <div>
        <div className="text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
                We manage your existing cloud applications
            </h1>
            <div className="flex mt-6 justify-center">
                <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
            </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div className="p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <EfficiencyAndCostIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Efficiency and cost</h2>
                    <p className="leading-relaxed text-base">
                        DevSecOps and quality implementations allow crisis management nearer to the point of failure;
                        self-healing AI aware issue diagnostics allows for higher-level approaches and strategies for
                        root cause resolution and keeps processes running smoothly.
                    </p>
                </div>
            </div>
            <div className="p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <AvailabilityAndResillienceIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Availabilty and resilience</h2>
                    <p className="leading-relaxed text-base">
                        Quality Engineering ensures robut systems designed from the ground up prioritizing routine
                        maintenance and growth strategies; optimizing operation up-time and reducing negative impacts
                        scheduled downtime with rolling-updates and CICD execution plans.
                    </p>
                </div>
            </div>
            <div className="p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <SpeedAndAgilityIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Speed and agilty</h2>
                    <p className="leading-relaxed text-base">
                        Rapid updates and enhancements deployed synchronously with business-aligned operations to
                        improve outcomes.
                    </p>
                </div>
            </div>

            <div className="p-4 md:w-1/4 md:mb-0 mb-6 flex flex-col text-center items-center">
                <div className="w-16  inline-flex items-center justify-center rounded-full">
                    <SecurityIcon fill={'#fff'} width="100" height="100" />
                </div>
                <div className="flex-grow">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Security and reliability</h2>
                    <p className="leading-relaxed text-base">
                        Pre-emptively mitigate the ever present security threats with confidence and assurance. React
                        aggressively and reapidly to attacks and threat models with risk compliant strategies and best
                        practises.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default SecondPage;
