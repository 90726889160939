import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';

export default function MyFils({ data }: { data: any }): React.ReactElement {
    console.log(data);
    return (
        <Layout>
            <div>All Files</div>
            <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
            <div>
                {data.allMarkdownRemark.edges.map(
                    (
                        {
                            node,
                        }: {
                            node: {
                                id: string;
                                frontmatter: {
                                    title: string;
                                    date: string;
                                };
                                excerpt: string;
                                fields: {
                                    slug: string;
                                };
                            };
                        },
                        index,
                    ): any => {
                        return (
                            <div key={node.id}>
                                <Link to={node.fields.slug}>
                                    <h3>{node.frontmatter.title}</h3>
                                    <p>{node.excerpt}</p>
                                </Link>
                            </div>
                        );
                    },
                )}
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
    }
`;
