import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Link from 'gatsby-link';

// import TravelIcon from './Icons/Travel';
import TravelIcon from '../../../../assets/icons/airplane.svg';
import IntelligentConnectedOperationsServicesIcon from '../../../../assets/icons/intelligent-cloud-and-infrustructure.svg';
import BusinessProcessAutomationIcon from '../../../../assets/icons/business-insight.svg';
import BigDataServicesIcon from '../../../../assets/icons/applied-intelligence.svg';
import ArtificialIntellilgenceServicesIcon from '../../../../assets/icons/intelligent-platform-engineering-services.svg';
import FinanceTransformationServicesIcon from '../../../../assets/icons/finance-and-accounting.svg';
import AdvancedAnalyticsServicesIcon from '../../../../assets/icons/analytics-on-demand.svg';
import TalenAndTransformationServicesIcon from '../../../../assets/icons/talent-and-hr.svg';
import ProcurementServicesIcon from '../../../../assets/icons/supply-chain-and-operations.svg';
import RiskAndFraudManagementServicesIcon from '../../../../assets/icons/security.svg';
import BlockchainServicesIcon from '../../../../assets/icons/line-graph.svg';
import CognitiveCustomerCareIcon from '../../../../assets/icons/technology-strategy-and-advisory.svg';

type Props = {
    className: string;
    bgColor: string;
    waveColor: string;
};

const BI = styled('div')`
    background-color: rgba(19, 96, 239, 0.02);
    ${tw`p-6 rounded-lg`}

    p {
        color: #8090b5;
    }
`;

const El = ({ Icon, title, content, slug }: { Icon: string; title: string; content: string }) => {
    return (
        <div className="xl:w-1/3 md:w-1/2 p-4">
            <BI>
                <div className="relative flex items-center justify-center mb-2">
                    <div className="w-10 h-10 inline-flex items-center rounded-full   justify-center">
                        <Icon fill={'#4a5568'} width="100" height="100" />
                    </div>
                    <h2 className="ml-4 text-xl inline-flex font-medium title-font">{title}</h2>
                </div>
            </BI>
        </div>
    );
};

const Industries = [
    {
        Icon: IntelligentConnectedOperationsServicesIcon,
        title: `Intelligent Connected Operations Services`,
    },
    {
        Icon: BusinessProcessAutomationIcon,
        title: `Business Process Automation Services`,
    },

    {
        Icon: ArtificialIntellilgenceServicesIcon,
        title: `Artificial Intelligence Services`,
    },

    {
        Icon: BigDataServicesIcon,
        title: `Big Data services`,
    },

    {
        Icon: FinanceTransformationServicesIcon,
        title: `Finance Transformation Services`,
    },

    {
        Icon: AdvancedAnalyticsServicesIcon,
        title: `Advanced Analytics Services`,
    },

    {
        Icon: TalenAndTransformationServicesIcon,
        title: `Talent and Transformation Services`,
    },

    {
        Icon: ProcurementServicesIcon,
        title: `Procurement Services`,
    },

    {
        Icon: RiskAndFraudManagementServicesIcon,
        title: `Risk and Fraud Management services`,
    },

    {
        Icon: BlockchainServicesIcon,
        title: `Blockchain Services`,
    },

    {
        Icon: CognitiveCustomerCareIcon,
        title: `Cognitive Customer Care`,
    },
];

export default function Comp(): React.ReactElement {
    return (
        <section className="text-gray-700 body-font">
            <div className="container px-5 py-5 mx-auto">
                <h1 className="text-3xl font-medium title-font mb-8">Business Process Services</h1>

                <div className="flex flex-wrap -m-4">
                    {Industries.map((industry) => (
                        <El key={industry.title} {...industry} />
                    ))}
                </div>
            </div>
        </section>
    );
}
