// Text Ready OK
import React from 'react';

const SecondPage = () => (
    <>
        <div className="flex flex-col text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">ENTERPRISE CLOUD</h2>
            <h1 className="sm:text-3xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                A cloud strategy to deliver success
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl" textReady={60}>
                Cloud Strategy for Enterprise helps create an interconnected cloud strategy where your business goals
                align to your IT architecture. Our capabilities can address your specific needs, including:
            </p>
        </div>
        <div className="flex flex-wrap text-lg">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6">
                <p className="leading-relaxed mb-4">Migration and modernization</p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <p className="leading-relaxed mb-4">DevOps transformation</p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <p className="leading-relaxed mb-4 text-lg">Transformation and outcomes.</p>
            </div>
        </div>
    </>
);

export default SecondPage;
