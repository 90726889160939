// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';

import Intro from '../../../../components/page/Intro/Intro';
import ContentBlock from '../../../../components/page/Content/block';
import SpeakToAnExpert from '../../../../components/page/SpeakToAnExpert';

import CloudServiceImage from '../../../../components/Images/infographics/data-modernization';

import GetStarted from './components/GettingStarted';
import Maximise from './components/MaximiseApplicationMigration';

import CollabImage from '../../../../components/Images/contextual/Collab';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4 mb-8">
                    <Intro
                        title="Increase Agility with Application Modernization"
                        subtitle="Transform existing IT and legacy operation with cutting-edge secure technologies and cloud strategies
                        "
                        // Image={() => <CloudServiceImage />}
                    />
                </div>

                <div className="container mx-auto flex mb-3 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                        <CloudServiceImage />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h2 className="text-xl mb-4">
                            Digital transformations are driven by the requirements of enterprise and business to enhance
                            their capabilities to meet demands of emerging markets and trends. Our teams can help
                            enhance your modernization and develop capabilities quickly and at scale.
                        </h2>

                        <p className="mb-4">
                            Modernize existing solutions and ecosystems to cloud-native infrastructures. Our team will
                            conduct thorough modernization assesments to develop detailed roadmaps, strategy guides that
                            target your architecture, with co-existence oriented development strategies and iterative
                            plans of action.
                        </p>

                        <p className="mb-4">
                            Microservices, containers and Kubernetes will enable tranformation to a cloud-native
                            architecture. Evolve with confidence and at scale with vetted and open plaforms, resilient
                            and robust solutions with integrated end-to-end testing.
                        </p>

                        <p className="text-lg" textReady={60}>
                            With the eperience of established industry methodologies and best practices from existing
                            client engagements, we can create a cloud modernization execution plan and prioritize the
                            critical steps, documenting the business impact and ROI.
                        </p>
                    </div>
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 py-4 mx-auto">
                <GetStarted />
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <div className="">
            <section
                style={{
                    backgroundColor: 'fff',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <div className="container px-5 py-4 mx-auto">
                    <Maximise />
                </div>

                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#1360ef' }}
                        ></path>
                    </svg>
                </div>
            </section>

            <section
                style={{
                    backgroundColor: '#1360ef',
                    paddingTop: '1rem',
                    paddingBottom: '15rem',
                    position: 'relative',
                }}
            >
                <SpeakToAnExpert
                    title="We can help with application modernization"
                    content="We can assist you at all stages in your transformation to a cloud-native organization. Regargless of whether you need to
                            modernize existing infrastrcuture or build cloud-native applications, our experienced strategy and
                            planning experts can help."
                />
                <div
                    style={{
                        position: 'absolute',
                        height: '200px',
                        width: '100%',
                        bottom: '0',
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                        <path
                            d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                            style={{ stroke: 'none', fill: '#fff' }}
                        ></path>
                    </svg>
                </div>
            </section>
        </div>
    </Layout>
);

export default SecondPage;
