export const COORDS = '';
export const PHONE = '+61-9741-4588';
export const ADDRESS = '1/41-43 Stewart St, Richmond VIC 3121, Australia';
export const NAME = 'Abrastal';
export const DESCRIPTION = `We bring together automation, Hybid MultiCloud, blockchain, Internet of Things, and AI to reimagine modern business architectures.`;

export const EMAIL = `info@${NAME.toLowerCase()}.com`;

export const keywords = [
    'Consulting',
    'Analytics',
    'Digital Solutions',
    'Engineering',
    'Cybersecurity',
    'Cloud',
    'Cloud Native',
    'Hybrid Cloud',
    'Cognitive Enterprise',
];

export const titleSEO =
    'Consulting | Analytics | Digital Solutions | Engineering | Cybersecurity | Cloud Native | Hybrid Cloud';

const AWS_BUCKET = `https://abrastal.s3.ap-southeast-2.amazonaws.com`;
const AWS_BUCKET_IMAGES = `${AWS_BUCKET}/images/`;

export const AWSImagePath = (filename: string): string => {
    return `${AWS_BUCKET_IMAGES}${filename}`;
};
