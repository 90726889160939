// Text Ready OK
import React from 'react';

const SecondPage = () => (
    <>
        <div className="text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">MODERNIZATION</h2>
            <h1 className="sm:text-3xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold">
                RAPID AND RESILIENT INNOVATION
            </h1>
            <div className="lg:w-2/3 mx-auto leading-relaxed ">
                <p className="text-2xl " textReady={80}>
                    Leverage the oppotunities of digital and modernization of your existing IT stack and embrace
                    emerging new trends to help propel your business into the higher levels of your industry and solve
                    the unique issues of your business.
                </p>
                <p className="text-lg" textReady={80}>
                    The challenges brought on by emergindg disrputive technologies and the pressures of industry
                    competition require smart and strategic approaches that reimagine the way your business integrates
                    hundreds of systems without negative impacts to routine business operations and customer experience.
                </p>
            </div>
        </div>
        <div className="flex flex-wrap text-lg">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6">
                <h3 className="text-xl font-semibold">Digital Business</h3>
                <p className="leading-relaxed mb-4" textReady={80}>
                    We help you improve your products and services to help you deliver better experiences to your
                    audiences and markets by engaging customers in more meaningful ways, and working with you to develop
                    new business models and trends. We help you differentiate amongst industry competition and delvier
                    meaningful and true value to your audience.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h3 className="text-xl font-semibold">Digital Operations</h3>
                <p className="leading-relaxed mb-4" textReady={80}>
                    We help you reinvent and rethink your business and transform to digital with optimized performance
                    through AI driven data analytics, automation and cognitive insights bringing closer together the
                    relationships with customers, suppliers and partners.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h3 className="text-xl font-semibold">Digital Systems & Technology</h3>
                <p className="leading-relaxed mb-4" textReady={80}>
                    We help you modernize your legacy applications, existing infrastructure and platforms to meet the
                    requirements of a successful digital enterprise.
                </p>
            </div>
        </div>
    </>
);

export default SecondPage;
