// Text Ready OK
import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import ScalabilityIcon from '../../../../assets/icons/scalability.svg';
import BusinessAgilityIcon from '../../../../assets/icons/business-agility.svg';
import FasterMigrationIcon from '../../../../assets/icons/faster-migration.svg';
import CostReductionIcon from '../../../../assets/icons/cost-reduction.svg';

const BodyContainer = styled.div`
    path {
        fill: #fff !important;
    }
`;

const SecondPage = () => (
    <>
        <section className="body-font text-center">
            <BodyContainer className="container px-5 mx-auto">
                <div className="flex flex-col text-center w-full mb-8">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 ">
                        We've helped our clients optimize their IT to achieve:
                    </h1>
                </div>
                <div className="flex flex-wrap">
                    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-gray-200 align-center">
                        <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                            <ScalabilityIcon fill={'#fff'} width="100" height="100" />
                        </div>
                        <h2 className="text-lg sm:text-xl font-medium title-font mb-2">Scalability</h2>
                        <p className="leading-relaxed text-base mb-4">72% saw greater scalability</p>
                    </div>
                    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 ">
                        <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                            <BusinessAgilityIcon fill={'#fff'} width="100" height="100" />
                        </div>
                        <h2 className="text-lg sm:text-xl font-medium title-font mb-2">Business Agility</h2>
                        <p className="leading-relaxed text-base mb-4">75% experienced increased operation agility</p>
                    </div>
                    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 ">
                        <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                            <FasterMigrationIcon fill={'#fff'} width="100" height="100" />
                        </div>
                        <h2 className="text-lg sm:text-xl font-medium title-font mb-2">Faster Migration</h2>
                        <p className="leading-relaxed text-base mb-4">20% faster time to cloud</p>
                    </div>
                    <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 ">
                        <div className=" w-16 inline-flex items-center rounded-full   justify-center">
                            <CostReductionIcon fill={'#fff'} width="100" height="100" />
                        </div>
                        <h2 className="text-lg sm:text-xl font-medium title-font mb-2">Cost Reduction</h2>
                        <p className="leading-relaxed text-base mb-4">25% reduced IT spend</p>
                    </div>
                </div>
            </BodyContainer>
        </section>
    </>
);

export default SecondPage;
