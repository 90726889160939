// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import Intro from './components/intro';
// import CollabImage from '../../../components/Images/Collab';
import Image from '../../components/Images/infographics/biometrics';

import { Industries, El } from '../../components/Industries/list';

import Services from '../services/business-strategy/components/services';
import EnterpriseFunctionConsulting from '../services/business-strategy/components/EnterpriseFunctionConsulting';

import SpeakToAnExpert from '../../components/page/SpeakToAnExpert';

import ServicesIBM from '../../components/ServicesIBM';
import Clients from '../../components/Clients';

import CollabImage from '../../components/Images/contextual/Industries';

const contiguousColor = '#ececec';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            style={{
                backgroundColor: 'fff',
                paddingTop: '1rem',
                paddingBottom: '3rem',
                position: 'relative',
            }}
        >
            <div className="py-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    <Intro />
                </div>
            </div>

            <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                    <Image />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1
                        className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900"
                        textSource="infosys"
                        textReady={80}
                    >
                        Digital innovation and customer focussed business models are creating new opportunities for
                        business.
                    </h1>
                    <h4 className="text-xl mb-4" textSource="infosys" textReady={80}>
                        Our innovations span industries and bolster advancements that help and guide business to adapt
                        to new paradigms of industry and technology.
                    </h4>
                    <p>
                        Our hybrid cloud architectures deliver higher-level value for businesses across five key areas –
                        Customer Experience, Market Insights, Innovatation, Time to Market and Resilience of Systems.
                    </p>
                </div>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container p-5 mx-auto">
                <Clients />
            </div>

            <div className="container px-5 mx-auto">
                <div className="flex py-5 md:flex-row flex-col items-center mb-12">
                    <div className="lg:flex-grow lg:w-3/4 md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center mb-12">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                            All industries are unique and face unique business requirements, but all share the same
                            digital goals.
                        </h1>
                        <h4 className="text-xl mb-4" textSource="infosys" textReady={80}>
                            Yet, enterprises share a common mandate: foster growth and refine business models, and
                            provide superior products and services and highest quality experience to their customers.
                        </h4>

                        <p className="mb-8 leading-relaxed text-lg" textSource="infosys" textReady={80}>
                            We combine digital potential with our unique provocative thinking to approach to help our
                            customers spanning industries develop, implement and deliver more innovative enterprises.
                        </p>
                    </div>

                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0  lg:pl-24 md:pl-16 ">
                        <CollabImage />
                    </div>
                </div>

                <div className="flex flex-wrap -m-4">
                    {Industries.map((industry) => (
                        <El key={industry.title} {...industry} />
                    ))}
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                title="We can help with application modernization"
                content=" We partner with and enable our clients at every step along the way of their journey to modernization
                rapidly and at scale. Learn how our dedicated strategy and application modernization experts can help."
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
