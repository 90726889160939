// Text Ready OK
import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import OurCultureIcon from '../../../assets/icons/our-culture.svg';
import OurPartnersIcon from '../../../assets/icons/our-partners.svg';
import OurSkillDevelopment from '../../../assets/icons/our-partners.svg';
import YearInFocusIcon from '../../../assets/icons/year-in-focus.svg';

const BodyContainer = styled.div`
    path {
        fill: #fff !important;
    }
`;

const c = '#8090b5';

const s = {
    backgroundColor: 'rgba(19, 96, 239, .1)',
};

const SecondPage = () => (
    <>
        <BodyContainer className="">
            <div className="flex flex-col text-center w-full mb-4">
                <h1 className="sm:text-3xl text-4xl font-bold title-font mb-6">A COMPANY BUILT FOR YOU</h1>
                <p className="text-xl mb-6">
                    Motivated by creating opportunities for businesses and organizations that improve society and the
                    communities we care about
                </p>
            </div>
            <div className="flex flex-wrap">
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6  h-full rounded-md" style={s}>
                        <div className="w-full flex align-center">
                            <div className=" w-20 inline-flex items-center rounded-full   justify-center">
                                <OurCultureIcon fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>

                        <h2 className="text-2xl sm:text-xl font-semibold title-font mb-2">Our culture</h2>
                        <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                            Composed of a diverse group of intrepid, inquisitive and unconvetional thinkers
                            collaborating with you to improve societies and communites we care about.
                        </p>
                    </div>
                </div>

                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6 h-full  rounded-md" style={s}>
                        <div className="w-full flex align-center">
                            <div className=" w-20 inline-flex items-center rounded-full   justify-center">
                                <OurPartnersIcon fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>
                        <h2 className="text-2xl sm:text-xl font-semibold title-font mb-2">Our partners</h2>
                        <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                            We partner with teams on the cutting-edge of innovation to push your business forward.
                        </p>
                    </div>
                </div>

                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6 h-full  rounded-md" style={s}>
                        <div className="w-full flex align-center">
                            <div className=" w-20 inline-flex items-center rounded-full   justify-center">
                                <OurSkillDevelopment fill={'#fff'} width="100" height="100" />
                            </div>
                        </div>
                        <h2 className="text-2xl sm:text-xl font-semibold title-font mb-2">Our skill development</h2>
                        <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                            We encourage the lifelong pursuit of knowledge, learning and skill development both
                            professionally and in the personal lives of our teams. The passion and love for learning
                            ensures that we are better equipped to lay the foundations for a more better future.
                        </p>
                    </div>
                </div>

                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-1">
                    <div className="px-8 py-6 h-full  rounded-md" style={s}>
                        <div className="w-full flex align-center">
                            <div className=" w-20 inline-flex items-center rounded-full   justify-center">
                                <YearInFocusIcon fill={'#fff'} width="100" height="100" style={{ fill: '#fff' }} />
                            </div>
                        </div>
                        <h2 className="text-2xl sm:text-xl font-semibold title-font mb-2">Us in focus</h2>
                        <p className="leading-relaxed text-lg mb-4" style={{ color: c }} textReady={80}>
                            A consistent vision, strong humanized values, ethical business practises, a stalwart
                            commitment to our clients, and an unrelenting drive to serve the ambitions of our partners,
                            the long-term goals of our clients and ensuring continuous value to the customers they
                            serve.
                        </p>
                    </div>
                </div>
            </div>
        </BodyContainer>
    </>
);

export default SecondPage;
