// Text Ready OK
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import Intro from './components/intro';
import SpeakToAnExpert from '../../components/page/SpeakToAnExpert';

import DigitalAtScale from './components/DigitalAtScale';
import OurCompany from './components/OurCompany';

import Image from '../../components/Images/infographics/intelligent-data-crunching';

const contiguousColor = '#ececec';
const darkBlue = '#060927';

const SecondPage = (props: PageProps) => (
    <Layout>
        <SEO title="Page two" />

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="pt-16 container mx-auto text-gray-700 text-center flex flex-col content-center">
                <div className="mx-auto lg:w-3/4">
                    <Intro />
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: darkBlue }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: darkBlue,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container px-5 mx-auto text-white">
                <OurCompany />
            </div>
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: '#fff',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container mx-auto flex py-5 mb-12 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                    {/* <img
                            className="object-cover object-center rounded"
                            alt="hero"
                            src="https://dummyimage.com/720x600"
                        /> */}
                    <Image />
                </div>
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-medium text-gray-900">
                        SMARTER INNOVATION
                    </h1>
                    <h4 className="text-xl mb-4" textReady={80}>
                        Together let's innovate and discover new ways to enhance your products and services, provide
                        exceptional experiences to the customers who use them, and serve our community that depends on
                        them.
                    </h4>

                    <p textReady={80}>
                        We fundametally evolve the mindset of our customers and help them create value over all layers
                        of the business at speed and scale while continuing to deliver benefits to their own audience
                        and market. Discover more about how we can help your business reacher higer-levels of return and
                        reach among the leaders of your industry.
                    </p>
                    <p textReady={80}>
                        Learn more about who we are and our core values, our diverse and rich culture and our commitment
                        to the promise of digital modernization.
                    </p>
                </div>
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: contiguousColor }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            className="text-gray-700 body-font"
            style={{
                backgroundColor: contiguousColor,
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <div className="container mx-auto py-5 mb-12 items-center">
                <DigitalAtScale />
            </div>

            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#1360ef' }}
                    ></path>
                </svg>
            </div>
        </section>

        <section
            style={{
                backgroundColor: '#1360ef',
                paddingTop: '1rem',
                paddingBottom: '15rem',
                position: 'relative',
            }}
        >
            <SpeakToAnExpert
                title="Connect With Us"
                content="We are driven by the new opportunities to enhance the business of our clients and delivering on the promise of digital to our communities."
            />
            <div
                style={{
                    position: 'absolute',
                    height: '200px',
                    width: '100%',
                    bottom: '0',
                    left: 0,
                    right: 0,
                }}
            >
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                        style={{ stroke: 'none', fill: '#fff' }}
                    ></path>
                </svg>
            </div>
        </section>
    </Layout>
);

export default SecondPage;
