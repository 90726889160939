// Text Ready OK
import React from 'react';

const SecondPage = () => (
    <>
        <div className="flex flex-col text-center w-full mb-8">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">MODERNIZATION</h2>
            <h1 className="sm:text-3xl text-6xl font-medium title-font mb-4 text-gray-900 font-semibold" textReady={80}>
                Are your legacy IT solutions ready for digital transformation?
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-2xl " textReady={80}>
                At every stage of you modernization journey, leveraging your existing IT investments to help you grow
                faster and securely. Our cloud specialists can help you:
            </p>
        </div>
        <div className="flex flex-wrap text-lg">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6">
                <p className="leading-relaxed mb-4">
                    textReady={80}
                    Design and deploy virtualized infrastrucure to make your operations agile and resilient.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <p className="leading-relaxed mb-4" textReady={80}>
                    Develop and execute a multicloud strategy prioritizing agility, security, and resiliency and meeting
                    management needs.
                </p>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <p className="leading-relaxed mb-4" textReady={80}>
                    Modernize legacy workflows across private and public cloud environments according to client
                    specifications and capabilities.
                </p>
            </div>
        </div>
    </>
);

export default SecondPage;
