// Text Ready OK
import React from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

type Props = {
    className: string;
    bgColor: string;
    waveColor: string;
};

const BI = styled('div')`
    background-color: rgba(19, 96, 239, 0.02);
    ${tw`p-6 rounded-lg`}

    p {
        color: #8090b5;
    }
`;

const El = ({ Icon, title, content, slug }: { Icon: string; title: string; content: string }) => {
    return (
        <div className="xl:w-1/3 md:w-1/2 p-4">
            <BI>
                <div className="relative flex items-center justify-center mb-2">
                    <h2 className="ml-4 text-xl inline-flex font-medium title-font">{title}</h2>
                </div>
                <p>{content}</p>
            </BI>
        </div>
    );
};

// textReady={80}
const Industries = [
    {
        title: `Talent and Organization Services`,
        content: 'Digitally optmize HR with AI assisted hiring decisions and talent acquisition and retention',
    },
    {
        title: `Enterprise Value`,
        content: 'Increase your financial reach and outcomes across the enterprise.',
    },
    {
        title: `Marketing, Sales, and Service`,
        content: 'Achieve personalized, responsible and relevant customer experiences.',
    },
    {
        title: `Strategic Sourcing Operations`,
        content:
            'Leverage AI solutions to optimize supply chain and operations in competitive industrusies and existing markets.',
    },
    {
        title: `Technology Strategy`,
        content: 'Drive higher-level outcomes with innovation-led strategy and cutting-edge technologies.',
    },
];

const SecondPage = () => (
    <>
        <h1 className="text-3xl font-medium title-font mb-8">
            What are the long-term benefits to my business of making these changes permanent?
        </h1>

        <div className="flex flex-wrap -m-4">
            {Industries.map((industry) => (
                <El key={industry.title} {...industry} />
            ))}
        </div>
    </>
);

export default SecondPage;
