// Text Ready OK
import React from 'react';

import Intro from '../../../components/page/Intro/Intro';
// import Image from '../../../services/images/services-multicloud';

const SecondPage = () => (
    <>
        <Intro
            tags="Strategy Consulting | Interactive | Operations | Technology"
            title="Industries"
            subtitle="Discover how breakthroughs in technology are re-inventing industries with more intelligent and effective ways to do business."
            // Image={() => <Image />}
        />
    </>
);

export default SecondPage;
